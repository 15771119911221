import Tooltip from "@/components/Tooltip"
import { ReactNode } from "react"
import { ReactComponent as InfoIcon } from "@/icons/info-icon.svg"

const Label = ({ text, info }: { text: string; info?: ReactNode }) => {
  return (
    <div className="flex h-full items-center">
      <span className="type-heading-xs font-bold">{text}</span>
      {info && (
        <Tooltip content={info}>
          <InfoIcon className="ml-2 h-3 w-3 cursor-pointer text-dove-gray" />
        </Tooltip>
      )}
    </div>
  )
}

export default Label
