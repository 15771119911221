import StatusBadge from "@/components/StatusBadge"
import Sidebar, { SidebarMenu, SidebarMenuLink } from "@/components/interface/Sidebar"
import { DEVICE_CONNECTED, DEVICE_SETTINGS_URL, FIRMWARE_URL } from "@/constants"
import { useSerialDevice } from "@/contexts/serial-device"
import {
  FIRMWARE_ERROR,
  FIRMWARE_OUTDATED,
  FIRMWARE_UP_TO_DATE,
  FIRMWARE_LOADING,
  useFirmware,
} from "@/hooks/firmware"
import { ReactComponent as ElementIcon } from "@/icons/element-icon.svg"
import { ReactComponent as ToolIcon } from "@/icons/tool-icon.svg"

const SettingsSidebar = () => {
  const device = useSerialDevice()
  const { firmwareStatus, latestFirmware, deviceFirmwareVersion } = useFirmware()

  return (
    <Sidebar>
      {device.status === DEVICE_CONNECTED && firmwareStatus !== FIRMWARE_LOADING && (
        <>
          <StatusWidget />
          <SidebarMenu>
            <SidebarMenuLink
              link={DEVICE_SETTINGS_URL}
              icon={<ElementIcon />}
              label="Element Settings"
            />
            <SidebarMenuLink
              link={FIRMWARE_URL}
              icon={<ToolIcon />}
              label={
                <>
                  <span>Firmware</span>
                  {firmwareStatus === FIRMWARE_ERROR && (
                    <span className="type-body-sm ml-3 inline-block font-normal italic text-red">
                      Error loading version
                    </span>
                  )}
                  {firmwareStatus === FIRMWARE_UP_TO_DATE && (
                    <span className="type-body-sm ml-3 inline-block font-normal italic text-bright-green">
                      Up to date (version {deviceFirmwareVersion})
                    </span>
                  )}
                  {firmwareStatus === FIRMWARE_OUTDATED && (
                    <span className="type-body-sm ml-3 inline-block font-normal italic text-orange-400">
                      Outdated ({deviceFirmwareVersion} &raquo; {latestFirmware.version})
                    </span>
                  )}
                </>
              }
            />
          </SidebarMenu>
        </>
      )}
    </Sidebar>
  )
}

const StatusWidget = () => {
  return (
    <div className="relative overflow-hidden rounded-lg">
      <img
        className="w-full"
        src={`${process.env.PUBLIC_URL}/element-4-thumb.jpg`}
        alt="Element IV"
      />
      <div className="type-heading-xs absolute left-5 top-4 font-bold">Element 4</div>
      <StatusBadge className="absolute right-2 top-3" small />
    </div>
  )
}

export default SettingsSidebar
