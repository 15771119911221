/**
 * Helper to introduce an async "wait" timer. Useful for UI elements where you
 * want a slight delay before show/hide.
 *
 * @param ms
 * @returns
 */
const asyncTimeout = (ms: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}

export { asyncTimeout }
