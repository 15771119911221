import { Switch as NextUISwitch } from "@nextui-org/switch"

const Switch = (props) => {
  return (
    <NextUISwitch
      classNames={{
        wrapper: ["h-6 mr-0 px-0", "bg-tundora", "group-data-[selected=true]:bg-dark-green"],
        thumb: [
          "bg-silver",
          "w-6 h-6",
          "group-data-[selected=true]:bg-green group-data-[selected=true]:ml-6 rtl:group-data-[selected=true]:mr-6",
        ],
      }}
      {...props}
    />
  )
}

export default Switch
