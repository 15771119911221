import cx from "classnames"
import Container from "@/components/Container"
import { Link } from "react-router-dom"

type Props = {
  showBackground?: boolean
}

const Footer = ({ showBackground }: Props) => {
  return (
    <footer
      className={cx("z-10 w-full flex-shrink-0 border-t border-mine-shaft", {
        "bg-dark": !showBackground,
      })}
    >
      <Container>
        <div className="type-body-base flex h-14 items-center">
          <div className="flex items-center gap-2">
            <span>© 2024 JDS Labs, Inc</span>
            <span>|</span>
            <Link to="/privacy-policy" className="hover:underline">
              Privacy
            </Link>
            <span>|</span>
            <a href="https://jdslabs.com/support/contact" className="hover:underline" target="_blank" rel="noopener noreferrer">
              Support
            </a>
          </div>
        </div>
      </Container>
    </footer>
  )
}

export default Footer
