import Button from "@/components/Button"
import Modal from "@/components/modals/Modal"
import { validateTextSettings } from "@/utils/equaliser"
import { readFileAsync } from "@/utils/file"
import { ModalBody, ModalContent, ModalFooter } from "@nextui-org/react"
import cx from "classnames"
import { fromEvent } from "file-selector"
import { useEffect, useState } from "react"
import { DropEvent, useDropzone } from "react-dropzone"

import { ReactComponent as CheckIcon } from "@/icons/check-icon.svg"
import { ReactComponent as CloudIcon } from "@/icons/cloud-icon.svg"

const ImportPresetModal = ({ isOpen, onClose, onImportPresetConfirmed, ...props }) => {
  const {
    getRootProps,
    getInputProps,
    open,
    isDragAccept,
    isDragReject,
    acceptedFiles,
    fileRejections,
    isDragActive,
  } = useDropzone({
    accept: {
      "text/plain": [".txt"],
    },
    maxFiles: 1,
    noClick: true,
    noKeyboard: true,
    // react-dropzone doesn't support async validators so we need to use this
    // function to pre-read the content of the text file so that it's available
    // when it's passed to the validator. Slightly hacky, see for more:
    // https://github.com/react-dropzone/react-dropzone/issues/1161
    getFilesFromEvent: async (event: DragEvent | DropEvent | FileSystemFileHandle[]) => {
      const files = await fromEvent(event)
      return Promise.all(
        Array.from(files)
          .filter((file) => file instanceof File)
          .map(async (file) => {
            if (file.type === "text/plain") {
              Object.defineProperty(file, "content", {
                value: await readFileAsync(file),
              })
            }
            return file
          })
      )
    },
    // Validate the content of the uploaded text file to make sure it conforms
    // the format required to convert to our JSON settings.
    validator: (file) => {
      if (!file.content) {
        return {
          code: "no-content",
          message: "Couldn't read file content",
        }
      }

      const errors = validateTextSettings(file.content)
      if (errors.length > 0) {
        console.log("Validation errors in imported file:")
        errors.forEach((error) => console.log(`- ${error}`))
        return errors.map((error) => {
          return {
            code: "validation-error",
            message: error,
          }
        })
      }

      return null
    },
  })

  /* Handle modal state */

  const [acceptedFile, setAcceptedFile] = useState()
  const [errors, setErrors] = useState<string[]>([])

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      setAcceptedFile(acceptedFiles[0])
    }
  }, [acceptedFiles])

  useEffect(() => {
    if (fileRejections.length > 0) {
      setErrors(fileRejections[0].errors)
    }
  }, [fileRejections])

  const reset = () => {
    setAcceptedFile(null)
    setErrors([])
  }

  // Reset on open
  useEffect(() => {
    if (isOpen) {
      reset()
    }
  }, [isOpen])

  return (
    <Modal isOpen={isOpen} {...props}>
      <ModalContent>
        {(onClose) => (
          <>
            <ModalBody className="pb-0">
              <div className="type-heading-3xl font-bold text-white">
                Import from AutoEQ, Squiglink, etc.
              </div>
              <div className="type-body-base text-spun-pearl">
                You may import up to 10 bands, including up to 8 Peaking filters, 1 Lowshelf filter,
                and 1 Highshelf filter. Please see{" "}
                <a
                  href="/example-import.txt"
                  download
                  className="text-red transition-colors hover:text-dark-red"
                >
                  example-import.txt
                </a>{" "}
                for expected format.
              </div>
              <div className="">
                <div
                  className={cx(
                    "mt-2 flex min-h-[400px] flex-col items-center justify-center rounded-lg border p-12",
                    {
                      "border-dashed border-dark-red bg-red bg-opacity-10 text-red": isDragReject,
                      "border-dashed border-silver bg-black bg-opacity-20": isDragAccept,
                      "border-mine-shaft": !isDragActive,
                    }
                  )}
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <div
                      className={cx(
                        "type-body-2xl font-medium",
                        isDragReject ? "text-dark-red" : "text-tundora"
                      )}
                    >
                      Drop the file here
                    </div>
                  ) : (
                    <>
                      <CloudIcon className="mb-10 h-14 w-14 text-tundora" />
                      <div className="flex max-w-md flex-col gap-y-4 text-center">
                        <div className="type-body-2xl font-medium text-tundora">
                          Drop your file here, or select the file from your computer
                        </div>
                        <div className="mt-10">
                          <Button theme="secondary" onPress={open}>
                            Select file
                          </Button>
                        </div>
                        {acceptedFile && (
                          <div className="type-body-lg mt-2 inline-flex items-center self-center rounded-lg border border-dark-green bg-green bg-opacity-20 px-4 py-2 font-bold text-white">
                            {acceptedFile.name} ({acceptedFile.size} bytes){" "}
                            <CheckIcon className="ml-3 h-4 w-4 text-green" />
                          </div>
                        )}
                        {errors.length > 0 && (
                          <>
                            {errors.map((error, i) => {
                              return (
                                <div
                                  key={`error-${i}`}
                                  className="type-body-lg mt-2 font-bold text-red"
                                >
                                  {error.message}
                                </div>
                              )
                            })}
                          </>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </ModalBody>
            <ModalFooter className="justify-start">
              <div className="flex gap-2 pb-3">
                {acceptedFile && (
                  <>
                    <Button
                      theme="secondary"
                      onPress={() => {
                        onImportPresetConfirmed(acceptedFile)
                        onClose()
                      }}
                    >
                      Import Preset
                    </Button>
                    <Button theme="neutral" onPress={onClose}>
                      Cancel
                    </Button>
                  </>
                )}
              </div>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}

export default ImportPresetModal
