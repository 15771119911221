import Button from "@/components/Button"
import TextInput from "@/components/forms/TextInput"
import AccountSidebar from "@/components/interface/AccountSidebar"
import Layout from "@/components/layouts/DefaultLayout"
import Settings, { SettingsField, SettingsFooter } from "@/components/Settings"
import { ACCOUNT_URL } from "@/constants"
import { AuthError, useAuth } from "@/contexts/auth"
import { ErrorMessage, Field, Form, Formik } from "formik"
import { useEffect } from "react"
import { Outlet as SubPage, useLocation, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import * as yup from "yup"

const AccountPage = () => {
  const { isAuthenticated } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate("/")
      toast.warning("You must be logged in")
    }
  }, [])

  return (
    <Layout>
      <AccountSidebar />
      <div className="flex w-full flex-1 pb-8 pl-80 pt-32">
        <div className="relative w-full max-w-7xl px-20 pb-14">
          {location.pathname === ACCOUNT_URL ? <MainPage /> : <SubPage />}
        </div>
      </div>
    </Layout>
  )
}

const MainPage = () => {
  const { deleteAccount, updateAccount, user, logout } = useAuth()
  const navigate = useNavigate()

  /**
   * Delete user account, logout and then redirect to home page
   */
  const onDeleteAccountClicked = async () => {
    try {
      const confirm = await window.confirm("Are you sure you want to delete your account?")
      if (confirm) {
        await deleteAccount()
        await logout()
        navigate("/")
      }
    } catch (error) {
      toast.error("Error deleting account")
    }
  }

  /**
   * Submit changes to account
   **/
  const onSubmit = async (values) => {
    try {
      await updateAccount(values)
      toast.success("Account updated")
    } catch (error) {
      console.error(error.errors)
      if (error instanceof AuthError) {
        toast.error("Unexpected error. Please contact support.")
      } else {
        toast.error("Unexpected error. Please contact support.")
      }
    }
  }

  return (
    <>
      <Settings title="Account Settings">
        {user && (
          <Formik
            initialValues={{ name: user.name, email: user.email }}
            validationSchema={yup.object({
              name: yup.string().required("Name is required"),
              email: yup.string().email().required("Email is required"),
            })}
            onSubmit={onSubmit}
          >
            <Form>
              <div className="space-y-6">
                <SettingsField label="Name" htmlFor="name">
                  <Field as={TextInput} type="text" name="name" placeholder="Name" />
                  <ErrorMessage component="div" className="mt-1 text-xs text-red" name="name" />
                </SettingsField>
                <SettingsField label="Email" htmlFor="email">
                  <Field as={TextInput} name="email" type="email" placeholder="Email" />
                  <ErrorMessage component="div" className="mt-1 text-xs text-red" name="email" />
                </SettingsField>
                <SettingsFooter>
                  <Button type="submit">Save changes</Button>
                  <Button theme="secondary" onClick={onDeleteAccountClicked}>
                    Delete account
                  </Button>
                </SettingsFooter>
              </div>
            </Form>
          </Formik>
        )}
      </Settings>
    </>
  )
}

export default AccountPage
