import { DEVICE_CONNECTED } from "@/constants"
import { axios } from "@/contexts/auth"
import { useSerialDevice } from "@/contexts/serial-device"
import { useEffect, useState } from "react"
import { compareVersionStrings } from "@/utils/string"

type Firmware = {
  version: string
  description: string
  ordering: number
  firmware_file: string
}

export const FIRMWARE_UNKNOWN = "unknown"
export const FIRMWARE_LOADING = "loading"
export const FIRMWARE_ERROR = "error"
export const FIRMWARE_OUTDATED = "outdated"
export const FIRMWARE_UP_TO_DATE = "up-to-date"

/**
 * Firmware hook
 *
 * NOTE: This hook handles logic surrounding the frontend and server firmware
 * fetching, not the actual updating of firmware on the device. In other words
 * it handles fetching firmware from the server and comparing it to what's
 * currently on the device.
 */
export function useFirmware() {
  const [firmwareStatus, setFirmwareStatus] = useState<string>(FIRMWARE_LOADING)
  const [deviceFirmwareVersion, setDeviceFirmwareVersion] = useState<string | null>(null)
  const [serverFirmwares, setServerFirmwares] = useState<Firmware[] | null>(null)
  const [latestFirmware, setLatestFirmware] = useState<Firmware | null>(null)

  const device = useSerialDevice()

  /**
   * Load current server firmware versions
   *
   */
  useEffect(() => {
    ;(async () => {
      try {
        const response = await axios.get("/api/firmware/")

        const firmwares = response.data
        const sortedFirmwares = firmwares.toSorted((a, b) =>
          compareVersionStrings(a.version, b.version)
        )
        sortedFirmwares.reverse()
        const latestFirmware_ = sortedFirmwares.length > 0 ? sortedFirmwares[0] : null

        setLatestFirmware(latestFirmware_)
        setServerFirmwares(sortedFirmwares)
        // setFirmwareStatus(FIRMWARE_UNKNOWN)
      } catch (error) {
        console.error(error)
        setFirmwareStatus(FIRMWARE_ERROR)
      }
    })()
  }, [])

  /**
   * Set the current device firmware version string when it connects
   *
   */
  useEffect(() => {
    if (device.status === DEVICE_CONNECTED) {
      ;(async () => {
        const response = await device.getSettings()
        setDeviceFirmwareVersion(response["Firmware"])
      })()
    }
  }, [device.status])

  /**
   * Calculate whether the device is outdated, once the server firmware versions
   * have been loaded as well as the current device firmware version
   *
   */
  useEffect(() => {
    if (serverFirmwares && serverFirmwares.length > 0 && deviceFirmwareVersion && latestFirmware) {
      const isOutdated = compareVersionStrings(latestFirmware.version, deviceFirmwareVersion) > 0
      setFirmwareStatus(isOutdated ? FIRMWARE_OUTDATED : FIRMWARE_UP_TO_DATE)
    }
  }, [serverFirmwares, deviceFirmwareVersion, latestFirmware])

  return {
    firmwareStatus,
    serverFirmwares,
    latestFirmware,
    deviceFirmwareVersion,
  }
}
