import Button from "@/components/Button"
import Container from "@/components/Container"
import StatusBadge from "@/components/StatusBadge"
import {
  ACCOUNT_URL,
  DEVICE_SETTINGS_URL,
  LOGIN_URL,
  ROOT_URL,
  DEVICE_CONNECTED,
  NO_DEVICES,
  FIRMWARE_URL,
} from "@/constants"
import { FIRMWARE_OUTDATED, useFirmware } from "@/hooks/firmware"
import { useAuth } from "@/contexts/auth"
import { useSerialDevice } from "@/contexts/serial-device"
import { ReactComponent as SettingsIcon } from "@/icons/settings-icon.svg"
import { ReactComponent as UserIcon } from "@/icons/user-icon.svg"
import { ReactComponent as DSPIcon } from "@/icons/dsp-icon.svg"
import { ReactComponent as ToolIcon } from "@/icons/tool-icon.svg"
import cx from "classnames"
import { NavLink } from "react-router-dom"

const DeviceSettingsLink = () => {
  const { status } = useSerialDevice()

  return (
    <NavLink
      to={status === DEVICE_CONNECTED ? DEVICE_SETTINGS_URL : ""}
      className={({ isActive }) =>
        cx(
          "type-body-sm relative flex items-center gap-x-2 border-b-4 pb-5 pl-3 pr-5 pt-6 font-bold",
          status === DEVICE_CONNECTED
            ? {
                "border-red": isActive,
                "clickable cursor-pointer border-transparent": !isActive,
              }
            : "cursor-not-allowed border-transparent opacity-20"
        )
      }
    >
      <SettingsIcon />
      Device Settings
    </NavLink>
  )
}

type Props = {
  showBackground: boolean
}

const Header = ({ showBackground }: Props) => {
  const { status, request } = useSerialDevice()
  const { firmwareStatus, latestFirmware, deviceFirmwareVersion } = useFirmware()
  const { isAuthenticated } = useAuth()

  return (
    <header
      className={cx("z-10 w-full flex-shrink-0 pt-2", {
        "border-b border-mine-shaft bg-dark": !showBackground,
      })}
    >
      <Container>
        <div className="flex items-center justify-between">
          <NavLink to={ROOT_URL}>
            <img src={`${process.env.PUBLIC_URL}/logo.svg`} alt="Logo" />
          </NavLink>
          <div className="flex items-center space-x-9">
            {firmwareStatus === FIRMWARE_OUTDATED && (
              <NavLink
                to={FIRMWARE_URL}
                className="type-body-base flex cursor-pointer items-center rounded-md border border-tundora py-1.5 pl-3 pr-6 font-bold transition-colors hover:border-silver"
              >
                <ToolIcon className="mr-4 w-4" />
                FW
                <span className="type-body-sm ml-3 inline-block font-normal italic text-orange-400">
                  Update Available ({deviceFirmwareVersion} &raquo; {latestFirmware.version})
                </span>
              </NavLink>
            )}
            <div className="flex items-center gap-x-4">
              <div className="type-heading-base font-bold">Device Status:</div>
              {status === NO_DEVICES && (
                <Button
                  type="secondary"
                  size="small"
                  outline
                  onClick={() => {
                    request()
                  }}
                >
                  Connect to Device
                </Button>
              )}
              {status !== NO_DEVICES && <StatusBadge />}
            </div>
            <nav>
              <ul className="flex items-center space-x-8">
                <li>
                  <NavLink
                    to={ROOT_URL}
                    className={({ isActive }) =>
                      cx(
                        "type-body-sm relative flex cursor-pointer items-center gap-x-2 border-b-4 pb-5 pl-3 pr-5 pt-6 font-bold",
                        isActive ? "border-red" : "clickable border-transparent"
                      )
                    }
                  >
                    <DSPIcon className="w-4.5" />
                    DSP Settings
                  </NavLink>
                </li>
                <li>
                  <DeviceSettingsLink />
                </li>
                <li>
                  {isAuthenticated() ? (
                    <NavLink
                      to={ACCOUNT_URL}
                      className={({ isActive }) =>
                        cx(
                          "type-body-sm relative flex cursor-pointer items-center gap-x-2 border-b-4 pb-5 pl-3 pr-5 pt-6 font-bold",
                          isActive ? "border-red" : "clickable border-transparent"
                        )
                      }
                    >
                      <UserIcon className="h-4 w-4" />
                      My Account
                    </NavLink>
                  ) : (
                    <NavLink
                      to={LOGIN_URL}
                      className={({ isActive }) =>
                        cx(
                          "type-body-sm relative flex cursor-pointer items-center gap-x-2 border-b-4 pb-5 pl-3 pr-5 pt-6 font-bold",
                          isActive ? "border-red" : "clickable border-transparent"
                        )
                      }
                    >
                      <UserIcon className="h-4 w-4" />
                      Login
                    </NavLink>
                  )}
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </Container>
    </header>
  )
}

export default Header
