import Layout from "@/components/layouts/DefaultLayout"

const PrivacyPolicy = () => {
  return (
    <Layout>
      <div className="flex w-full flex-1 pt-32">
        <div className="container mx-auto px-4 max-w-4xl">
          <h1 className="text-2xl font-bold mb-6">Privacy Policy for JDS Labs Core™</h1>
          
          <p className="mb-4">Last Updated: Nov 4, 2024</p>
          
          <p className="mb-6 font-normal">
            Welcome to JDS Labs Core™. We value your privacy and are committed to protecting your personal information. 
            This Privacy Policy explains how we collect, use, and protect information when you use our web app ("Core") 
            to connect with and manage your USB DAC.
          </p>

          <section className="mb-8">
            <h2 className="text-xl font-semibold mb-4">1. Device Connection and Permissions</h2>
            <p className="mb-4 font-normal">
              To utilize the Core app with your USB DAC, a browser with WebSerial and WebUSB capabilities is required. 
              These permissions allow Core to connect securely to your DAC, enabling you to manage DSP settings and 
              device configurations directly from the web app.
            </p>
            <p className="mb-4 font-normal">
              You must click "Connect to Device" to grant access to your JDS Labs USB device. Only upon your explicit 
              authorization will the browser be permitted to connect. Your browser will remember the connection for your 
              convenience, allowing future access without additional connection prompts.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold mb-4">2. Information Collection and Use</h2>
            <p className="mb-4 font-normal">
              The use of Core does not require a customer account or login. However, if you wish to save multiple DSP profiles, 
              you have the option to create an account. If you choose to do so, we only request your name and email address. 
              These details are solely for the purpose of storing and retrieving your DSP profiles across sessions.
            </p>
            <ul className="list-disc pl-6 mb-4">
              <li className="mb-2 font-normal">Optional Information: Name and email address for DSP profile storage.</li>
              <li className="mb-2 font-normal">Purpose: To allow you to manage and access multiple DSP settings profiles.</li>
            </ul>
            <p className="mb-4 font-normal">
              You may delete your optional account at any time, removing all associated DSP profile data from our servers.
            </p>
            <p className="mb-4 font-normal">
              We may also collect and use traffic information (e.g., app usage statistics) to improve Core's functionality, 
              as well as our products and firmware.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold mb-4">3. Data Privacy and Security</h2>
            <p className="mb-4 font-normal">We are committed to safeguarding the privacy of your data:</p>
            <ul className="list-disc pl-6 mb-4">
              <li className="mb-2 font-normal">
                Data Usage: Your information remains private and will never be sold or used for unsolicited purposes. 
                We only access the information necessary to provide our services and enhance Core's performance.
              </li>
              <li className="mb-2 font-normal">
                Data Protection: We implement industry-standard security measures to protect your information from unauthorized 
                access, use, or disclosure. However, please note that no transmission over the internet or electronic storage 
                method is 100% secure.
              </li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold mb-4">4. Independence from Ecommerce Platform</h2>
            <p className="mb-4 font-normal">
              Core runs independently from our ecommerce platform and has no access to your shopping history, financial 
              information, or payment services. Core's sole function is to facilitate interaction with your USB DAC for 
              audio and DSP management purposes.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold mb-4">5. Cookies and Local Storage</h2>
            <p className="mb-4 font-normal">
              Core may use cookies or local storage to remember your device connection, so you can reconnect easily without 
              reauthorization. This data is stored locally in your browser and is not accessible by us or any third parties.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold mb-4">6. Third-Party Disclosure</h2>
            <p className="mb-4 font-normal">
              We do not sell, trade, or otherwise transfer your personal information to third parties. Core is designed to 
              protect your privacy by keeping all user data and device connection information private and securely managed.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold mb-4">7. Changes to this Privacy Policy</h2>
            <p className="mb-4 font-normal">
              We may update this Privacy Policy to reflect changes to our information practices. We encourage you to review 
              this page periodically for the latest information on our privacy practices.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold mb-4">8. Contact Us</h2>
            <p className="mb-4 font-normal">
              If you have any questions regarding this Privacy Policy or Core's privacy practices, please {' '}
              <a href="https://jdslabs.com/support/contact" className="text-blue-600 hover:underline" target="_blank" rel="noopener noreferrer">reach out to us</a>.
            </p>
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default PrivacyPolicy