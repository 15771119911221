import { Progress as NextUIProgress } from "@nextui-org/progress"

const Progress = ({ value, ...props }) => {
  return (
    <div className="relative">
      <div
        className="absolute left-1 top-4 h-1 rounded-full bg-red bg-gradient-to-r from-dark to-red blur-[10px] transition-all duration-500 ease-linear"
        style={{ width: `${value}%` }}
      ></div>
      <NextUIProgress
        size="sm"
        aria-label="Loading..."
        showValueLabel={true}
        classNames={{
          base: "flex-col-reverse mt-4",
          track: "bg-spun-pearl",
          indicator: "bg-red ease-linear",
          label: "tracking-wider font-medium text-default-600",
          value: "text-red",
        }}
        value={value}
        {...props}
      />
    </div>
  )
}

export default Progress
