import Button from "@/components/Button"
import { ReactComponent as BinIcon } from "@/icons/bin-icon.svg"
import { ReactComponent as LinkIcon } from "@/icons/link-icon.svg"
import { ReactComponent as StarIcon } from "@/icons/star-icon.svg"
import type { Preset } from "@/hooks/presets"
import cx from "classnames"
import { useSerialDevice } from "@/contexts/serial-device"
import { DEVICE_CONNECTED } from "@/constants"

const PresetHeader = ({
  activePreset,
  activePresetOutdated,
  onDeletePresetClicked,
  onExportClicked,
  onFavouritePresetClicked,
  onSavePresetClicked,
  onSaveNewPresetClicked,
  onGetLinkClicked,
}: {
  activePreset: Preset
  activePresetOutdated: boolean
  onDeletePresetClicked: (preset: Preset) => void
  onExportClicked: () => void
  onFavouritePresetClicked: (preset: Preset) => void
  onSavePresetClicked: (preset: Preset) => void
  onSaveNewPresetClicked: (preset: Preset) => void
  onGetLinkClicked: () => void
}) => {
  const { status } = useSerialDevice()
  const isDeviceConnected = status === DEVICE_CONNECTED

  return (
    <div className="flex items-center justify-between">
      {activePreset ? (
        <div className="type-heading-base inline-flex items-center font-bold">
          {activePreset.name}
          <button
            title={"Make this preset a favourite"}
            className="cursor-pointer"
            onClick={() => onFavouritePresetClicked(activePreset)}
          >
            <StarIcon
              className={cx(
                "-mt-px ml-4 h-4 w-4",
                activePreset.is_favourite ? "text-yellow" : "text-gray-300"
              )}
            />
          </button>
        </div>
      ) : (
        <div className="type-heading-base inline-flex items-center font-bold">No Preset Loaded</div>
      )}
      <div className="flex items-center gap-x-2">
        {activePreset && (
          <>
            {activePresetOutdated && (
              <>
                <span className="mr-1 text-xs italic text-dark-blue">Preset changed</span>
                <Button
                  theme="secondary"
                  size="small"
                  title={`Save EQ changes to current '${activePreset.name}' preset`}
                  disabled={!activePresetOutdated}
                  outline
                  onClick={() => onSavePresetClicked(activePreset)}
                >
                  Update Preset
                </Button>
              </>
            )}
          </>
        )}
        <Button
          theme="secondary"
          size="small"
          title="Create a new preset from current EQ settings"
          outline
          onClick={() => onSaveNewPresetClicked(activePreset)}
          disabled={!isDeviceConnected}
        >
          Save As New Preset
        </Button>
        <Button
          className="pr-2"
          theme="secondary"
          size="small"
          outline
          onClick={() => onGetLinkClicked()}
        >
          Get link
          <LinkIcon className="-mt-0.5 h-3 w-3 text-brightest-blue" />
        </Button>
        <Button
          theme="secondary"
          size="small"
          outline
          onClick={() => onExportClicked()}
          title="Export the current EQ settings"
          disabled={!isDeviceConnected}
        >
          Export
        </Button>
        {activePreset && (
          <div className="flex items-center pl-1">
            <button
              className="clickable h-4 w-4 text-silver"
              title="Delete this preset"
              onClick={() => onDeletePresetClicked(activePreset)}
            >
              <BinIcon />
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

export default PresetHeader
