import Accordion from "@/components/Accordion"
import Button from "@/components/Button"
import InlineNotification from "@/components/InlineNotification"
import LoadingSpinner from "@/components/LoadingSpinner"
import ConfirmationModal from "@/components/modals/ConfirmationModal"
import FirmwareModal from "@/components/modals/FirmwareModal"
import ProgressModal from "@/components/modals/ProgressModal"
import { DEVICE_CONNECTED, JDSLABS_VENDOR_ID } from "@/constants"
import { useSerialDevice } from "@/contexts/serial-device"
import {
  FIRMWARE_ERROR,
  FIRMWARE_LOADING,
  FIRMWARE_OUTDATED,
  FIRMWARE_UNKNOWN,
  FIRMWARE_UP_TO_DATE,
  useFirmware,
} from "@/hooks/firmware"
import dfu from "@/libs/dfu"
import { asyncTimeout } from "@/utils/async"
import { useDisclosure } from "@nextui-org/react"
import { useRef, useState } from "react"
import { toast } from "react-toastify"

/**
 * Firmware page
 *
 * Allow the user to see their current firmware version and update their device
 * with the latest firmware. Firmware versions come from the API server. The
 * local firmware version on the device comes from the serial connection. Only
 * when a user attempts to install a new firmware version do we attempt to make
 * a USB connection to the device.
 */
const FirmwarePage = () => {
  const device = useSerialDevice()
  const { firmwareStatus, latestFirmware, serverFirmwares, deviceFirmwareVersion } = useFirmware()
  const targetFirmware = useRef()
  const firmwareData = useRef<ArrayBuffer | null>(null)
  const [showFirmwareVersions, setShowFirmwareVersions] = useState<boolean>(false)
  const [progressTitle, setProgressTitle] = useState<string>("Loading firmware ...")
  const [progressDescription, setProgressDescription] = useState<string>(
    "Please wait. Do not close this browser tab."
  )
  const [progressValue, setProgressValue] = useState<number>(0)

  const {
    isOpen: confirmationModalOpen,
    onClose: closeConfirmationModal,
    onOpen: openConfirmationModal,
  } = useDisclosure()

  const {
    isOpen: transferModalOpen,
    onClose: closeTransferModal,
    onOpen: openTransferModal,
  } = useDisclosure()

  const {
    isOpen: prepareModalOpen,
    onClose: closePrepareModal,
    onOpen: openPrepareModal,
  } = useDisclosure()

  const {
    isOpen: progressModalOpen,
    onClose: closeProgressModal,
    onOpen: openProgressModal,
  } = useDisclosure()

  /**
   * Step 1: Show confirmation dialog
   *
   */
  const onInstallClicked = async (firmware) => {
    targetFirmware.current = firmware
    openConfirmationModal()
  }

  /**
   * Step 2: Begin installation
   *
   * When the user clicks "confirm" in the confirmation dialog, we begin the
   * installation process. This involves downloading the firmware from the
   * server before showing the user the next step of the process (preparation)
   */
  const onConfirmClicked = async () => {
    // Show progress modal
    setProgressTitle("Downloading firmware ....")
    setProgressValue(50)
    setProgressDescription("Please select your JDS Labs device via the in-browser pop-up ...")
    await openProgressModal()

    // Download firmware
    // TODO: Include a progress update during download
    try {
      const response = await fetch(targetFirmware.current.firmware_file)
      if (!response.ok) {
        throw new Error("Error downloading firmware file")
      }
      const blob = await response.blob()
      firmwareData.current = await blob.arrayBuffer()
    } catch (error) {
      console.error(error)
      toast.error("Download of latest firmware failed")
      closeProgressModal()
      return
    }

    // Move to "prepaaration" modal
    await asyncTimeout(1000)
    closeProgressModal()
    openPrepareModal()
  }

  /**
   * Step: 3 Prepare device
   *
   * We now put the device into  DFU mode so that it can accept the transfer.
   * This will update the display on the device to showing "updating". Furthermore,
   * this will disconnect both the serial and usb connecitons from the webapp.
   * We will need to reconnect to the device manually, so we require a second confirmation
   * dialog to kick-off the transfer process.
   */
  const onPrepareClicked = async () => {
    // Show progress modal
    setProgressTitle("Preparing device ....")
    setProgressValue(50)
    setProgressDescription("Please select your JDS Labs device via the in-browser pop-up ...")
    await openProgressModal()

    try {
      // Select device
      const usbDevice = await navigator.usb.requestDevice({
        filters: [{ vendorId: JDSLABS_VENDOR_ID }],
      })

      // Put device into upgrad mode
      const dfuInterfaces = await dfu.findDeviceDfuInterfaces(usbDevice)
      const dfuDevice = new dfu.Device(usbDevice, dfuInterfaces[0])
      await dfuDevice.open()
      await dfuDevice.startUpdate()
    } catch (error) {
      console.error(error)
      toast.error("Device preparation failed")
      closeProgressModal()
      closePrepareModal()
      return
    }

    // Move to "transfer"
    await asyncTimeout(1000)
    closePrepareModal()
    openTransferModal()
  }

  /**
   * Step 4: Transferring
   *
   */
  const onTransferClicked = async () => {
    // Show progress modal
    setProgressTitle("Transferring firmware ....")
    setProgressValue(0)
    setProgressDescription("Please re-select your device so that we can start the transfer ...")

    await openProgressModal()
    await closeTransferModal()

    try {
      // Re-select device
      const usbDevice = await navigator.usb.requestDevice({
        filters: [{ vendorId: JDSLABS_VENDOR_ID }],
      })
      setProgressDescription(
        "Transfer started, please don't refresh the page or disconnect your device ..."
      )

      // Transfer the firmware
      const dfuInterfaces = await dfu.findDeviceDfuInterfaces(usbDevice)
      const dfuDevice = new dfu.Device(usbDevice, dfuInterfaces[0])
      await dfuDevice.open()
      dfuDevice.logProgress = (bytes_written, bytes_total) => {
        if (!bytes_written || !bytes_total) {
          return
        }
        setProgressValue((parseFloat(bytes_written) * 100.0) / parseFloat(bytes_total))
      }
      await dfuDevice.do_download(64, firmwareData.current)

      // Put device back into regular mode (which resets it)
      await dfuDevice.finishUpgrade()
    } catch (error) {
      console.error(error)
      toast.error("Firmware installation failed")
      closeProgressModal()
      return
    }

    toast.success("Firmware successfully updated")
    closeProgressModal()
  }

  if (firmwareStatus === FIRMWARE_ERROR) {
    return (
      <div className="mt-4 max-w-[400px]">
        <InlineNotification level="error" showIcon={false}>
          <>
            There was an error loading firmware versions from our server. Please try reload the page
            and try again.
          </>
        </InlineNotification>
      </div>
    )
  }

  if (firmwareStatus === FIRMWARE_LOADING) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <span className="flex items-center gap-2">
          <LoadingSpinner size="small" />{" "}
          <span className="text-xs text-gray-500">LOADING DEVICE SETTINGS</span>
        </span>
      </div>
    )
  }

  return (
    <>
      <ProgressModal
        title={progressTitle}
        value={progressValue}
        description={progressDescription}
        isOpen={progressModalOpen}
        onClose={closeProgressModal}
      />

      {/* Confirmation modal */}
      <ConfirmationModal
        question="Are you sure you would like to install this version?"
        description={`You're about to install the firmware version ${targetFirmware.current ? targetFirmware.current.version : ""}`}
        confirmText="Yes, I'm sure"
        isOpen={confirmationModalOpen}
        onClose={closeConfirmationModal}
        onConfirm={onConfirmClicked}
      />

      {/* Preparation modal  */}
      <FirmwareModal
        title="Step 1: Select your device"
        description={
          "In order to update your firmware we need to connect to your device via USB. After clicking 'continue' you'll be prompted to select your device. Once selected, your device will enter 'upgrade mode' and then disconnect again from the app."
        }
        continueText="Select device"
        isOpen={prepareModalOpen}
        onContinue={onPrepareClicked}
      />

      {/* Tranfer modal */}
      <FirmwareModal
        title="Step 2: Transfer the firmware"
        description={`Your device is now in 'upgrade mode'. Please press 'continue' to transfer the new firmware. If you face any issues, you can simply restart your device to get back to regular operation.`}
        continueText="Continue"
        isOpen={transferModalOpen}
        onContinue={onTransferClicked}
      />

      <h3 className="type-heading-2xl font-bold">Firmware</h3>

      {firmwareStatus === FIRMWARE_UNKNOWN && (
        <div className="type-body-lg mt-5">
          Your device is not currently connected. Here are the most recent firmware versions.
        </div>
      )}

      {firmwareStatus === FIRMWARE_OUTDATED && (
        <div className="type-body-lg mt-5">
          You are running Firmware Version {deviceFirmwareVersion}. Version {latestFirmware.version}{" "}
          is now available.
        </div>
      )}

      {firmwareStatus === FIRMWARE_UP_TO_DATE && (
        <div className="type-body-lg mt-5">
          You are running Firmware Version {deviceFirmwareVersion}. You are up to date!
        </div>
      )}

      {showFirmwareVersions ||
      firmwareStatus === FIRMWARE_OUTDATED ||
      firmwareStatus === FIRMWARE_UNKNOWN ? (
        <Accordion
          className="mb-32 mt-12"
          items={serverFirmwares.map((firmware) => {
            return {
              title: `Version ${firmware.version}`,
              content: (
                <FirmwareItem
                  deviceStatus={device.status}
                  firmware={firmware}
                  onInstallClicked={onInstallClicked}
                />
              ),
            }
          })}
        />
      ) : (
        <button
          className="type-body-lg mt-8 cursor-pointer text-red transition-colors hover:text-dark-red"
          onClick={() => setShowFirmwareVersions(true)}
        >
          Show previous firmware versions
        </button>
      )}
      {/* <Divider />
      <DeviceSummary></DeviceSummary> */}
    </>
  )
}

const DeviceSummary = () => {
  return (
    <div>
      <h3 className="type-heading-2xl font-bold">Device Info</h3>
      <ul className="type-body-base mt-6 space-y-1 rounded-md border border-mine-shaft px-3 py-4 font-bold">
        <li>Manufacturer: JDS Labs</li>
        <li>Product: USB Receiver</li>
        <li>VID / PID: 046d:c534</li>
        <li>Serial Number:</li>
        <li>Firmware version: 41.0.1</li>
      </ul>
    </div>
  )
}

const FirmwareItem = ({ deviceStatus, firmware, onInstallClicked }) => {
  return (
    <div>
      {firmware.description && (
        <>
          <div className="type-body-base font-medium">Release Notes:</div>
          <div
            className="prose prose-sm prose-white mt-4 max-w-4xl"
            dangerouslySetInnerHTML={{ __html: firmware.description }}
          ></div>
        </>
      )}
      {deviceStatus === DEVICE_CONNECTED && (
        <Button className="mt-8" onPress={() => onInstallClicked(firmware)}>
          Install this version
        </Button>
      )}
    </div>
  )
}

export default FirmwarePage
