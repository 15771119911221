import InlineNotification from "@/components/InlineNotification"
import Button from "@/components/Button"
import TextInput from "@/components/forms/TextInput"
import LoginLayout from "@/components/layouts/LoginLayout"
import { AuthError, useAuth } from "@/contexts/auth"
import { Field, Form, Formik } from "formik"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

const ForgotPasswordPage = () => {
  const [sent, setSent] = useState(false)
  const { requestPasswordReset } = useAuth()
  return (
    <LoginLayout
      title="Forgot password?"
      subtitle="No worries, we'll send you reset instructions"
      showBackButton
    >
      {!sent ? (
        <Formik
          initialValues={{ email: "" }}
          onSubmit={async (values) => {
            try {
              await requestPasswordReset({
                email: values.email,
              })
              setSent(true)
            } catch (error) {
              console.error(error)
              if (error instanceof AuthError) {
                if (error.code) {
                  toast.error(error.message)
                } else {
                  toast.error("Something went wrong. Please contact support.")
                }
              }
            }
          }}
        >
          <Form>
            <div className="space-y-6">
              <Field
                as={TextInput}
                name="email"
                type="email"
                theme="shadow"
                placeholder="Email"
                autoFocus={true}
              />
              <Button type="submit" full>
                Reset password
              </Button>
            </div>
          </Form>
        </Formik>
      ) : (
        <InlineNotification level="success" showIcon={true}>
          <>Alright, check your email</>
        </InlineNotification>
      )}
    </LoginLayout>
  )
}

export default ForgotPasswordPage
