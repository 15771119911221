import LoginLayout from "@/components/layouts/LoginLayout"
import InlineNotification from "@/components/InlineNotification"

const SignUpComplete = () => {
  return (
    <LoginLayout
      title="Check your email"
      subtitle="We've sent you a verification email. "
      showBackButton
      wide
    >
      <InlineNotification>
        <>Please check your inbox and click the link to create your account.</>
      </InlineNotification>
    </LoginLayout>
  )
}

export default SignUpComplete
