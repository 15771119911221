import Settings, { SettingsField, SettingsFooter } from "@/components/Settings"
import TextInput from "@/components/forms/TextInput"
import Button from "@/components/Button"
import { Field, Form, Formik, ErrorMessage } from "formik"
import { toast } from "react-toastify"
import * as yup from "yup"
import { AuthError, useAuth } from "@/contexts/auth"

const ChangePasswordPage = () => {
  const { changePassword } = useAuth()

  const onSubmit = async (values, { resetForm }) => {
    try {
      await changePassword(values)
      resetForm()
      toast.success("Password changed")
    } catch (error) {
      console.error(error.errors)
      if (error instanceof AuthError) {
        if (error.errors) {
          error.errors.forEach(([msg]) => {
            toast.error(msg)
          })
        } else {
          toast.error(error.message)
        }
      } else {
        toast.error("Unexpected error. Please contact support.")
      }
    }
  }
  return (
    <>
      <Settings title="Reset Password">
        <Formik
          initialValues={{ old_password: "", password: "", password_confirm: "" }}
          validationSchema={yup.object({
            old_password: yup.string().min(8).required("Current password is required"),
            password: yup
              .string()
              .min(8)
              .required("New password is required")
              .notOneOf(
                [yup.ref("old_password")],
                "New password must be different to old password"
              ),
            password_confirm: yup
              .string()
              .min(8)
              .required("New password confirmation is required")
              .oneOf([yup.ref("password")], "New passwords must match"),
          })}
          onSubmit={onSubmit}
        >
          <Form>
            <div className="space-y-6">
              <SettingsField label="Current Password" htmlFor="old_password">
                <Field as={TextInput} type="password" name="old_password" autoFocus={true} />
                <ErrorMessage
                  component="div"
                  className="mt-1 text-xs text-red"
                  name="old_password"
                />
              </SettingsField>
              <SettingsField label="New Password" htmlFor="password">
                <Field as={TextInput} type="password" name="password" />
                <ErrorMessage component="div" className="mt-1 text-xs text-red" name="password" />
              </SettingsField>
              <SettingsField label="Confirm New Password" htmlFor="password_confirm">
                <Field as={TextInput} type="password" name="password_confirm" />
                <ErrorMessage
                  component="div"
                  className="mt-1 text-xs text-red"
                  name="password_confirm"
                />
              </SettingsField>
              <SettingsFooter>
                <Button type="submit">Reset password</Button>
              </SettingsFooter>
            </div>
          </Form>
        </Formik>
      </Settings>
    </>
  )
}

export default ChangePasswordPage
