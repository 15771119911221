import Button from "@/components/Button"
import TextInput from "@/components/forms/TextInput"
import Modal from "@/components/modals/Modal"
import { ModalBody, ModalContent, ModalFooter } from "@nextui-org/modal"
import { ErrorMessage, Field, Form, Formik } from "formik"
import * as yup from "yup"

type Props = {
  onConfirm: (values) => void
}

const NewPresetModal = ({ onConfirm, ...props }: Props) => {
  return (
    <Modal {...props}>
      <ModalContent>
        {(onClose) => (
          <Formik
            initialValues={{ name: "" }}
            validationSchema={yup.object({
              name: yup.string().min(3).max(30).required("Name is required"),
            })}
            onSubmit={onConfirm}
          >
            <Form>
              <ModalBody>
                <h2 className="type-heading-3xl font-bold text-white">Save New Preset</h2>
                <div className="type-body-base text-spun-pearl">
                  Give your preset a name to make it easy to find in the sidebar.
                </div>
                <div className="mt-4">
                  <Field as={TextInput} name="name" type="text" autoFocus={true} />
                  <ErrorMessage component="div" className="mt-1 text-xs text-red" name="name" />
                </div>
              </ModalBody>
              <ModalFooter className="justify-start">
                <div className="flex gap-2 pb-3">
                  <Button theme="secondary" size="large" type="submit">
                    Save
                  </Button>
                  <Button theme="neutral" size="large" onPress={onClose}>
                    Cancel
                  </Button>
                </div>
              </ModalFooter>
            </Form>
          </Formik>
        )}
      </ModalContent>
    </Modal>
  )
}

export default NewPresetModal
