import Modal from "@/components/modals/Modal"
import Progress from "@/components/forms/Progress"
import { ModalBody, ModalContent } from "@nextui-org/react"

const ProgressModal = ({ value, title, description, isOpen, ...props }) => {
  return (
    <Modal isOpen={isOpen} isDismissable={false} hideCloseButton={true} {...props}>
      <ModalContent>
        {(onClose) => (
          <>
            <ModalBody>
              <div className="type-heading-3xl font-bold text-white">{title}</div>
              {description && <div className="type-body-base text-spun-pearl">{description}</div>}
              <Progress className="mb-5" value={value} />
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}

export default ProgressModal
