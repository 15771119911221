import { useEffect, useRef, useState } from "react"

import {
  JDSLABS_VENDOR_ID,
  JDS_LABS_DEVICE_IDS,
  DEVICE_CONNECTED,
  DEVICE_DISCONNECTED,
  NO_DEVICES,
  DEVICE_UNSUPPORTED,
  UNKNOWN,
} from "@/constants"
import { toast } from "react-toastify"

const useSerial = () => {
  const [status, setSerialState] = useState(UNKNOWN)
  const portRef = useRef(null)
  const readerRef = useRef(null)
  const writerRef = useRef(null)
  const subscribers = useRef([])

  /**
   * Publish incoming data to serial port subscribers
   *
   * @param data
   */
  const publish = (data) => {
    subscribers.current.forEach((callback) => callback(data))
  }

  /**
   * Subscribe to serial port
   *
   * @param callback
   * @returns
   */
  const subscribe = (callback) => {
    subscribers.current.push(callback)
    return () => {
      subscribers.current = subscribers.current.filter((cb) => cb !== callback)
    }
  }

  /**
   * Identify a port with human-readable name
   *
   * @param port
   * @returns
   */
  const identify = (port) => {
    try {
      return JDS_LABS_DEVICE_IDS[port.getInfo().usbProductId]
    } catch (error) {
      return port.getInfo().usbProductId
    }
  }

  // Read from port
  const read = async (reader) => {
    try {
      let buffer = "",
        delimiter = "\0",
        delimiterIndex
      while (true) {
        const { value, done } = await reader.read()
        if (done) {
          break // Stream is closed
        }
        buffer += new TextDecoder().decode(value, { stream: true })

        if ((delimiterIndex = buffer.indexOf(delimiter)) > -1) {
          const message = buffer.slice(0, delimiterIndex)
          const jsonMessage = JSON.parse(message)
          buffer = ""
          console.debug(`Serial: receiving serial data from stream:`, jsonMessage)
          publish(jsonMessage)
        }
      }
    } catch (error) {
      console.error("Error reading from serial port:", error)
    }
  }

  /**
   * Write data to the open serial port
   *
   * @param data
   */
  const write = async (data) => {
    if (writerRef.current) {
      const payload = JSON.stringify(data)
      console.debug(`Serial: sending data over serial:`, payload)
      const encodedData = new TextEncoder().encode(payload + "\0")
      await writerRef.current.write(encodedData)
    } else {
      console.error("No writer available. Connect to the serial port first.")
    }
  }

  /**
   * Connect to a particular serial port
   *
   * @param port
   * @returns
   */
  const connect = async (port) => {
    if (status === DEVICE_CONNECTED) {
      console.warn(`Serial: port already open`)
      return false
    }
    try {
      await port.open({ baudRate: 19200 })
      portRef.current = port
      writerRef.current = port.writable.getWriter()
      readerRef.current = port.readable.getReader()
      read(readerRef.current)
      setSerialState(DEVICE_CONNECTED)
      console.debug(`Serial: port '${identify(port)}' opened`)
    } catch (error) {
      setSerialState(DEVICE_DISCONNECTED)
      console.error(error)
      // Likely that the serial port is already open in another browser
      toast.error("Failed to connect to the serial port")
    }
  }

  /**
   * Request a port from the browser
   *
   * @returns
   */
  const request = async () => {
    try {
      const port = await navigator.serial.requestPort({
        filters: [{ usbVendorId: JDSLABS_VENDOR_ID }],
      })
      console.debug(`Serial: user requested serial port '${identify(port)}' from browser`)
      await connect(port)
      return true
    } catch (error) {
      console.error(error)
    }
    return false
  }

  /**
   * Respond to a new connection by updating the status and tracking the port
   *
   * @param event
   */
  const onPortConnected = (event) => {
    const port = event.target
    portRef.current = port
    connect(port)
    console.debug(`Serial port '${identify(event.target)}' connected`)
  }

  /**
   * Respond to a disconnect by updating the status
   *
   * @param event
   */
  const onPortDisconnected = (event) => {
    portRef.current = null
    setSerialState(DEVICE_DISCONNECTED)
    console.warn(`Serial port '${identify(event.target)}' disconnected`)
  }

  /**
   * Attempt to connect to a serial port when the hook/app loads
   *
   */
  useEffect(() => {
    if ("serial" in navigator) {
      // Check for existing connection
      ;(async () => {
        const ports = await navigator.serial.getPorts()
        if (ports.length) {
          console.debug("Serial: available ports:")
          ports.forEach((port) => {
            console.debug(`- ${identify(port)}`)
          })
          connect(ports[0])
        } else {
          setSerialState(NO_DEVICES)
        }
      })()

      // Set up port event listeners
      navigator.serial.addEventListener("disconnect", onPortDisconnected)
      navigator.serial.addEventListener("connect", onPortConnected)
      return () => {
        navigator.serial.removeEventListener("disconnect", onPortDisconnected)
        navigator.serial.addEventListener("connect", onPortConnected)
      }
    } else {
      setSerialState(DEVICE_UNSUPPORTED)
    }
  }, [])

  return {
    request,
    status,
    write,
    subscribe,
  }
}

export { useSerial }
