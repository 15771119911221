import { createContext, useContext, useEffect, useState } from "react"

import { useSerial } from "@/hooks/serial"
import { useQueue } from "@/hooks/queue"
import { DEVICE_CONNECTED } from "@/constants"
import { applySettingsUpdate, fromSettingsUpdate } from "@/utils/device"

class SerialDeviceError extends Error {}

const SerialDeviceContext = createContext()

export function SerialDeviceProvider({ children }) {
  const serial = useSerial()
  const queue = useQueue({ maxSize: 10, pollTime: 200 })

  /**
   * Execute a command when received from the queue
   *
   * @param command
   * @param deferredPromise
   * @returns
   */
  const onCommandReceived = async (command, deferredPromise) => {
    console.debug(`Device: executing command:`, command)
    return new Promise((resolve) => {
      let timeout

      // Subscribe to serial port to read the next message
      const unsubscribe = serial.subscribe((data) => {
        clearTimeout(timeout)
        unsubscribe()
        deferredPromise.resolve(data)
        resolve()
      })

      // Set a timeout
      timeout = setTimeout(() => {
        console.warn("Device: command timed out")
        unsubscribe()
        deferredPromise(false)
        resolve()
      }, 5000)

      // Send command
      serial.write(command)
    })
  }

  /**
   * Request settings from the device
   *
   * @returns
   */
  const getSettings = async () => {
    // Push command to the queue
    const onResponseReceived = await queue.push({
      Product: "JDS Labs Element IV",
      Action: "Describe",
    })

    // Wait for reply from the serial device
    try {
      const response = await onResponseReceived.promise
      if (response["Status"] === true || response["Configuration"]) {
        console.debug("Device: Getting settings from device:", response)
        return response
      } else {
        throw new SerialDeviceError("Command error getting settings")
      }
    } catch (error) {
      console.error(error)
      throw new SerialDeviceError("Unexpected error getting settings")
    }
  }

  /**
   * Send an update to the device
   *
   * @param partialSetting a nested object that contains only the settings we
   * want to update, for example:
   * {
   *   "UI": {
   *     "Screen Timeout": "5 Seconds"
   *   }
   * }
   * @returns
   */
  const updateSettings = async (settingsUpdate) => {
    // Create command
    const command = {
      Product: "JDS Labs Element IV",
      "Format Output": true,
      Action: "Update",
      ...settingsUpdate,
    }

    // Push command to the queue
    const onResponseReceived = await queue.push(command)

    // Wait for reply from the serial device
    const response = await onResponseReceived.promise
    if (response["Status"] === true) {
      return response
    } else {
      throw new SerialDeviceError("Command error updating settings")
    }
  }

  /**
   * Subscribe to the queue and hook up callback for new commands
   *
   */
  useEffect(() => {
    const unsubscribe = queue.subscribe("executor", onCommandReceived)
    return () => {
      if (unsubscribe) unsubscribe()
    }
  }, [])

  return (
    <SerialDeviceContext.Provider value={{ ...serial, getSettings, updateSettings }}>
      {children}
    </SerialDeviceContext.Provider>
  )
}

export function useSerialDevice() {
  const context = useContext(SerialDeviceContext)
  if (!context) {
    throw new Error("useSerialDevice must be used within SerialDeviceProvider")
  }
  return context
}
