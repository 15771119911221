import Button from "@/components/Button"
import TextInput from "@/components/forms/TextInput"
import InlineNotification from "@/components/InlineNotification"
import LoginLayout from "@/components/layouts/LoginLayout"
import { AuthError, useAuth } from "@/contexts/auth"
import { ErrorMessage, Field, Form, Formik } from "formik"
import { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import { toast } from "react-toastify"
import * as yup from "yup"

const ResetPasswordPage = () => {
  const { resetPassword } = useAuth()
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const location = useLocation()

  const searchParams = new URLSearchParams(location.search)
  const user_id = searchParams.get("user_id")
  const signature = searchParams.get("signature")
  const timestamp = searchParams.get("timestamp")

  const onSubmit = async (values) => {
    try {
      await resetPassword({
        password: values.password,
        user_id,
        timestamp,
        signature,
      })
      setSuccess(true)
    } catch (error) {
      console.error(error)
      if (error instanceof AuthError) {
        if (error.code) {
          toast.error(error.message)
        } else {
          toast.error("Something went wrong. Please contact support.")
        }
      }
    }
  }

  useEffect(() => {
    if (!user_id || !signature || !timestamp) {
      setError(true)
      return
    }
  }, [])

  return (
    <LoginLayout title="Reset password" subtitle="Create a new password" showBackButton>
      {error ? (
        <InlineNotification level="error" showIcon={false}>
          <>
            There was an error resetting your password. Please{" "}
            <a className="underline" href="mailto:info@jdslabs.com">
              contact support
            </a>{" "}
            and we'll help get the issue sorted.
          </>
        </InlineNotification>
      ) : (
        <>
          {success ? (
            <InlineNotification level="success">
              <>
                Your password has been reset. Return to the{" "}
                <Link className="font-bold underline" to={{ pathname: "/login" }}>
                  login page
                </Link>{" "}
                and sign in.
              </>
            </InlineNotification>
          ) : (
            <Formik
              initialValues={{ password: "", password_confirm: "" }}
              validationSchema={yup.object({
                password: yup.string().min(8).required("Password is required"),
                password_confirm: yup
                  .string()
                  .min(8)
                  .required("Password confirmation is required")
                  .oneOf([yup.ref("password")], "Passwords must match"),
              })}
              onSubmit={onSubmit}
            >
              <Form>
                <div className="space-y-6">
                  <div>
                    <Field
                      as={TextInput}
                      type="password"
                      name="password"
                      theme="shadow"
                      placeholder="Password"
                      autoFocus={true}
                    />
                    <ErrorMessage
                      component="div"
                      className="mt-1 text-xs text-red"
                      name="password"
                    />
                  </div>
                  <div>
                    <Field
                      as={TextInput}
                      type="password"
                      name="password_confirm"
                      theme="shadow"
                      placeholder="Confirm password"
                    />
                    <ErrorMessage
                      component="div"
                      className="mt-1 text-xs text-red"
                      name="password_confirm"
                    />
                  </div>
                  <Button type="submit" full>
                    Reset password
                  </Button>
                </div>
              </Form>
            </Formik>
          )}
        </>
      )}
    </LoginLayout>
  )
}

export default ResetPasswordPage
