import { useRef, useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Slider as NextUISlider } from "@nextui-org/slider"

const Slider = ({ theme = "primary", formatValue, value, onDebouncedChange, ...props }) => {
  const [inputValue, setInputValue] = useState(value)

  const themes = {
    primary: {
      filler: "bg-red",
      track: "bg-catskill-white border-s-red",
      thumb: "bg-red after:bg-white",
    },
    secondary: {
      filler: "bg-white",
      track: "bg-mine-shaft border-s-white",
      thumb: "bg-jade after:bg-white",
    },
  }

  // Debounce the input changes and send event
  useEffect(() => {
    if (value !== inputValue) {
      const timeout = setTimeout(() => {
        console.debug("Debounced update from number input")
        onDebouncedChange(parseFloat(inputValue))
      }, 500)
      return () => clearTimeout(timeout)
    }
  }, [inputValue])

  // Update local value when prop value changes
  useEffect(() => {
    if (value !== inputValue) {
      setInputValue(value)
    }
  }, [value])

  return (
    <NextUISlider
      // showTooltip={true}
      classNames={themes[theme]}
      tooltipProps={{
        isOpen: true,
        showArrow: false,
        offset: 6,
        // classNames: {
        //   base: "before:bg-mine-shaft",
        //   content: "bg-mine-shaft rounded-md text-base font-bulo py-2 px-3",
        // },
        // style: { zIndex: 10 },
        content: formatValue ? formatValue(value) : value,
      }}
      defaultValue={value}
      onChange={(newValue) => {
        setInputValue(newValue)
      }}
      {...props}
    />
  )
}

Slider.propTypes = {
  theme: PropTypes.oneOf(["primary", "secondary"]),
  formatValue: PropTypes.func,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default Slider
