import InlineNotification from "@/components/InlineNotification"
import LoginLayout from "@/components/layouts/LoginLayout"
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import LoadingSpinner from "@/components/LoadingSpinner"
import { useAuth } from "@/contexts/auth"
import { toast } from "react-toastify"
import { Link } from "react-router-dom"

const SignUpVerification = () => {
  const { verifyRegistration } = useAuth()
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(true)
  const location = useLocation()

  const searchParams = new URLSearchParams(location.search)
  const user_id = searchParams.get("user_id")
  const signature = searchParams.get("signature")
  const timestamp = searchParams.get("timestamp")

  useEffect(() => {
    if (!user_id || !signature || !timestamp) {
      setError(true)
      return
    }

    // Verify
    ;(async () => {
      try {
        await verifyRegistration({
          user_id,
          timestamp,
          signature,
        })
        setSuccess(true)
      } catch (error) {
        toast.error("Error verifying account")
        setError(true)
      }
    })()
  }, [])

  return (
    <LoginLayout
      title={success ? "Email verified!" : "Verifying your email"}
      subtitle={
        success
          ? "Everything looks good, you can now log in"
          : "Hang tight, we're verifying your email"
      }
      showBackButton
      wide
    >
      {error ? (
        <InlineNotification level="error" showIcon={false}>
          <>
            There was an error verifying you account. Please{" "}
            <a className="underline" href="mailto:info@jdslabs.com">
              contact support
            </a>{" "}
            and we'll help get the issue sorted.
          </>
        </InlineNotification>
      ) : (
        <div className="flex w-full justify-center">
          {success ? (
            <InlineNotification level="success">
              <>
                Your account has been verified. Return to the{" "}
                <Link className="font-bold underline" to={{ pathname: "/login" }}>
                  login page
                </Link>{" "}
                and sign in.
              </>
            </InlineNotification>
          ) : (
            <LoadingSpinner></LoadingSpinner>
          )}
        </div>
      )}
    </LoginLayout>
  )
}

export default SignUpVerification
