import Axios, { AxiosRequestConfig } from "axios"
import { configure } from "axios-hooks"
import { createContext, useContext, useState, useEffect, useCallback, useRef } from "react"
import { isAxiosError } from "axios"

type RegisterProps = {
  name: string
  email: string
  password: string
  password_confirm: string
}

type LoginProps = {
  email: string
  password: string
}

type VerifyProps = {
  user_id: string
  signature: string
  timestamp: string
}

type RequestPasswordResetProps = {
  email: string
}

type ResetPasswordProps = {
  password: string
  user_id: string
  signature: string
  timestamp: string
}

// Dictated by django rest registration
type ChangePasswordProps = {
  old_password: string
  password: string
  password_confirm: string
}

type UpdateAccountProps = {
  name: string
  email: string
}

type User = {
  id: string
  name: string
  email: string
}

type Tokens = {
  access: string | null
  refresh: string | null
}

type Context = {
  user: User | null
  isAuthenticated: () => boolean
  tokens: Tokens
  login: ({ email, password }: LoginProps) => Promise<User>
  logout: () => void
  register: ({ email, password, password_confirm }: RegisterProps) => void
  verifyRegistration: ({ user_id, timestamp, signature }: VerifyProps) => void
  requestPasswordReset: ({ email }: RequestPasswordResetProps) => void
  resetPassword: ({ password, user_id, timestamp, signature }: ResetPasswordProps) => void
  changePassword: ({ old_password, password, password_confirm }) => void
  deleteAccount: () => void
  updateAccount: ({ name, email }: UpdateAccountProps) => void
  refreshAccessToken: () => void
}

/**
 * Custom Auth Exception
 *
 * A custom JS exception/error that lets us pass additional information back
 * up to the calling code so that they can report errors to the user more easily
 */
export class AuthError extends Error {
  code?: string
  errors?: any[]
  constructor(message: string, code?: string, errors?: any[]) {
    super(message)
    // User-friendly error message
    this.name = this.constructor.name
    // Code-friendly code
    this.code = code
    // Pass array of user-friendly errors to display to the user instead
    // of just the name/message of the error. Useful for when API returns
    // array of issues.
    this.errors = errors
  }
}

/**
 * Axios
 *
 * We configure axios globally to include the access token in requests automatically
 * when the user is logged in, and also to manage refresh tokens in the background
 */
export const axios = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

const AuthContext = createContext<Context | undefined>(undefined)

export function AuthProvider({ children }) {
  const [user, setUser] = useState<User | null>(null)
  const [tokens, setTokens] = useState<Tokens>({
    access: null,
    refresh: null,
  })
  const tokensRef = useRef<Tokens>()

  tokensRef.current = tokens

  const isAuthenticated = useCallback(() => {
    return user !== null
  }, [user])

  /**
   * Login
   *
   * @param param0
   * @returns
   */
  const login = async ({ email, password }: LoginProps): Promise<User> => {
    let tokens_: Tokens | undefined
    let user: User | undefined

    // Login and get tokens
    try {
      const tokenRepsonse = await axios.post("/api/accounts/login/", {
        login: email,
        password,
      })
      console.log(tokenRepsonse)
      tokens_ = tokenRepsonse.data.token
    } catch (error) {
      console.error(error)
      if (isAxiosError(error)) {
        if (error?.response?.status === 400) {
          if (error?.response?.data?.detail) {
            throw new AuthError("Incorrect email or password", "incorrect-details")
          }
        }
      }
      throw new AuthError("Error logging in", "unknown")
    }

    // Get the user's profile
    try {
      // NOTE: We need to manually add the token to this request as it hasn't
      // yet been saved and therefore the axios interceptor doesn't know about
      // it yet.
      const response = await axios.get("/api/accounts/profile/", {
        headers: {
          Authorization: `Bearer ${tokens_.access}`,
        },
      })
      user = response.data
    } catch (error) {
      console.error(error)
      if (isAxiosError(error)) {
        if (error?.response?.status === 400) {
          if (error?.response?.data?.detail) {
            throw new AuthError("Incorrect email or password", "incorrect-details")
          }
        }
      }
      throw new AuthError("Error logging in", "unknown")
    }

    setTokens(tokens_)
    setUser(user)

    // Save to localstorage
    const storageObject = {
      tokens: tokens_,
      user,
    }
    console.debug("Saving auth information to localstorage:", storageObject)
    localStorage.setItem("auth", JSON.stringify(storageObject))

    return user
  }

  /**
   * Register Account
   *
   * @param props
   * @returns
   */
  const register = async (props: RegisterProps) => {
    try {
      return await axios.post("/api/accounts/register/", props)
    } catch (error) {
      if (isAxiosError(error)) {
        if (error?.response?.status === 400) {
          if (error?.response?.data?.email) {
            throw new AuthError("Email is already registered", "already-registered")
          }
          if (error?.response?.data?.password) {
            throw new AuthError("Password is too common", "password-complexity")
          }
        }
      }
      throw new AuthError("Error logging in", "unknown")
    }
  }

  /**
   * Verify Registration
   *
   * @param props
   * @returns
   */
  const verifyRegistration = async (props: VerifyProps) => {
    try {
      return await axios.post("/api/accounts/verify-registration/", props)
    } catch (error) {
      throw new AuthError("Error verifying account", "unknown")
    }
  }

  /**
   * Request Password Reset Email
   *
   * @param param0
   * @returns
   */
  const requestPasswordReset = async ({ email }: RequestPasswordResetProps) => {
    try {
      return await axios.post("/api/accounts/send-reset-password-link/", {
        login: email,
      })
    } catch (error) {
      if (isAxiosError(error)) {
        if (error?.response?.status === 400) {
          if (error?.response?.data?.detail) {
            throw new AuthError("Email is already registered", "already-registered")
          }
          if (error?.response?.data?.password) {
            throw new AuthError("Password is too common", "password-complexity")
          }
        }
      }
      throw new AuthError("Error sending password reset email", "unknown")
    }
  }

  /**
   * Reset Password
   *
   * @param props
   * @returns
   */
  const resetPassword = async (props: ResetPasswordProps) => {
    try {
      return await axios.post("/api/accounts/reset-password/", props)
    } catch (error) {
      if (isAxiosError(error)) {
        if (error?.response?.status === 400) {
          if (error?.response?.data?.email) {
            throw new AuthError("Email is already registered", "already-registered")
          }
          if (error?.response?.data?.password) {
            throw new AuthError("Password is too common", "password-complexity")
          }
        }
      }
      throw new AuthError("Error logging in", "unknown")
    }
  }

  /**
   * Change Password
   */
  const changePassword = async (props: ChangePasswordProps) => {
    try {
      return await axios.post("/api/accounts/change-password/", props)
    } catch (error) {
      if (isAxiosError(error)) {
        console.log(error)
        if (error?.response?.status === 400) {
          if (error?.response?.data?.password) {
            throw new AuthError(
              "Error with password details",
              "password-complexity",
              Object.values(error.response.data)
            )
          }
        }
      }
      throw new AuthError("Error changing password", "unknown")
    }
  }

  /**
   * Delete Account
   *
   * @returns
   */
  const deleteAccount = async () => {
    try {
      return await axios.delete(`/api/accounts/delete/`)
    } catch (error) {
      console.error(error)
      throw new AuthError("Error deleting account", "unknown")
    }
  }

  /**
   * Update Account
   *
   */
  const updateAccount = async (values): Promise<User> => {
    try {
      const response = await axios.patch(`/api/accounts/update/`, values)
      setUser(response.data)
      return user
    } catch (error) {
      throw new AuthError("Error deleting account", "unknown")
    }
  }

  /**
   * Logout
   *
   */
  const logout = async () => {
    setUser(null)
    setTokens({
      access: null,
      refresh: null,
    })
    localStorage.removeItem("auth")
  }

  /**
   * Refresh Access Token
   */
  const refreshAccessToken = async () => {
    // Don't handle any exceptions or errors here, as this is called as
    // part of an axios interceptor that needs to check responses
    console.log("Attempting token refresh:", tokensRef.current.refresh)
    const response = await axios.post("/api/accounts/refresh/", {
      refresh: tokensRef.current.refresh,
    })
    setTokens({
      access: response.data.access,
      refresh: response.data.refresh,
    })
    tokensRef.current = {
      access: response.data.access,
      refresh: response.data.refresh,
    }
  }

  /**
   * Load Initial Auth
   *
   */
  useEffect(() => {
    const storageString = localStorage.getItem("auth")
    if (storageString) {
      console.debug("Loading auth information from local storage:", storageString)
      const storageObject = JSON.parse(storageString)
      setUser(storageObject.user)
      setTokens(storageObject.tokens)
    }
  }, [])

  /**
   * Configure Axios
   *
   * Axios should automatically use our access token when contacting the backend
   * server. We should also attempt to refresh the token if a 401 is encountered
   *
   *
   */
  useEffect(() => {
    // Inject the access token
    axios.interceptors.request.use((config) => {
      if (tokensRef.current.access) {
        config.headers.Authorization = `Bearer ${tokensRef.current.access}`
      }
      return config
    })

    // Attempt refresh
    axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error.response.status === 401) {
          // If we're in the process of getting an access token, skip
          if (error.config.url === "/api/accounts/login/") {
            console.debug("Failed login attempted, skipping token refresh ...")
          }
          // If we've already tried to refresh, then logout
          else if (error.config.url.includes("/api/accounts/refresh/")) {
            console.debug("Already tried refreshing token, logging out ...")
            logout()
          }
          // Try to refresh the token if we get a 401
          else if (!error.config._retry) {
            console.debug("Attempting to refresh access token")
            error.config._retry = true
            try {
              await refreshAccessToken()
              return axios(error.config)
            } catch (error) {
              logout()
            }
          }
        }
        return Promise.reject(error)
      }
    )

    configure({ axios })
  }, [tokens.access])

  const value = {
    user,
    isAuthenticated,
    tokens,
    register,
    verifyRegistration,
    login,
    logout,
    requestPasswordReset,
    resetPassword,
    changePassword,
    deleteAccount,
    updateAccount,
    refreshAccessToken,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export function useAuth() {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error("useAuth must be used within AuthProvider")
  }
  return context
}
