/**
 * Equaliser helper functions. These are primarily helper functions around the
 * custom JSON settings format that we use for describing the current state
 * of the equaliser. Be aware that these settings are only a subset of the full
 * device settings, or even the DSP settings.
 *
 * They look like:
 *
 *  {
 *   "Lowshelf": {
 *     "Frequency": {
 *       "Min": -30,
 *       "Max": 10000,
 *       "Current": 200
 *     },
 *     ...
 *   },
 *   ...
 * }
 *
 * Where each object in the JSON represents a band of the equaliser.
 *
 */

// Regex pattern for matching the preamp text settings in a Squiglink preset
const PREAMP_TEXT_PATTERN = /Preamp:\s*([-+]?[\d.]+) dB/
// Regex pattern for matching a filter line in the Squiglink preset
const FILTER_TEXT_PATTERN =
  /^Filter\s(\d+):\s(ON|OFF)\s(LSC|PK|HSC)\sFc\s(\d+(?:\.\d+)?)\sHz\sGain\s([-+]?\d+(?:\.\d+)?)\sdB\sQ\s(\d+(?:\.\d+)?)$/
// Regex for matching the peaking filter name in a JSON EQ settings object
const PEAKING_PATTERN = /^Peaking [1-8]$/

/**
 * Validate EQ settings. Our EQ settings are a JSON object that describe the
 * state of the equaliser. They look like:
 *
 * {
 *   "Lowshelf": {
 *     "Frequency": {
 *       "Min": -30,
 *       "Max": 10000,
 *       "Current": 200
 *     },
 *     ...
 *   },
 *   ...
 * }
 *
 *
 * @param eqSettings the EQ settings JSON object form the device or the EQ page
 * @returns array of errors if the eq settings are invalid, otherwise an empty
 * array if everything is OK
 */
const validateEqSettings = (eqSettings) => {
  const domainKeys = ["Gain", "Frequency", "Q"]
  const valueKeys = ["Min", "Max", "Current"]
  const errors = Object.entries(eqSettings).reduce((errors: string[], entry, i) => {
    const [key, value] = entry
    if (key === "Preamp") {
      return []
    } else if (["Highshelf", "Lowshelf"].includes(key) || PEAKING_PATTERN.test(key)) {
      const errors = []
      // Check keys for filters
      domainKeys.forEach((k1) => {
        if (typeof value[k1] === "undefined") {
          errors.push(`Key '${k1}' missing from '${key}'`)
        } else {
          // Check keys on each individual filter
          valueKeys.forEach((k2) => {
            if (typeof value[k1] === "undefined") {
              errors.push(`Key '${k2}' missing from '${k1}'`)
            } else {
              // Check type of min/max/current
              if (typeof value[k1][k2] !== "number") {
                errors.push(`Key '${k2}' in '${k1}' is not a number (${value[k1][k2]})`)
              } else {
                // Check that the value is within bounds
                if (
                  k2 === "Current" &&
                  (value[k1][k2] > value[k1]["Max"] || value[k1][k2] < value[k1]["Min"])
                ) {
                  errors.push(`Value '${value[k1][k2]} ('${k2}' in '${k1}') is invalid`)
                }
              }
            }
          })
        }
      })
      return errors
    } else {
      return errors + [`'${key}' is not a valid key for EQ settings`]
    }
  }, [])
  return errors
}

const validateUrlParamSettings = (queryParam: string): string[] => {
  const errors = []

  const validTypes = ["LSC", "HSC", "PK"]
  const eqTokens = queryParam.split("|")

  if (eqTokens.length !== 10) {
    errors.push("There must be 10 bands in the URL")
    return errors
  }

  eqTokens.forEach((token, i) => {
    const bandTokens = token.split(",")
    if (bandTokens.length !== 4) {
      errors.push(`Band ${i + 1} is invalid (must have 4 values)`)
      return
    }
    const [type, gain, frequency, qFactor] = bandTokens
    if (!validTypes.includes(type)) {
      errors.push(`Type must be one of ${validTypes.toString()} (for band ${i + 1})`)
      return
    }
    if (isNaN(gain) || gain < -30 || gain > 30) {
      errors.push(`Gain in band ${i + 1} must be a number between -30 and 30`)
      return
    }
    if (isNaN(frequency) || frequency < 20 || frequency > 20000) {
      errors.push(`Frequency in band ${i + 1} must be a number between 20 and 20000`)
      return
    }
    if (
      isNaN(qFactor) ||
      qFactor < 0.1 ||
      (type === "PK" && qFactor > 50) ||
      ((type === "LSC" || type === "HSC") && qFactor > 3)
    ) {
      errors.push(`Q factor in band ${i + 1} must be between 0.1 and ${type === "PK" ? "50" : "3"}`)
      return
    }
  })

  return errors
}

/**
 * Validate Squiglink-like text settings.
 *
 * - Must have a preamp line
 * - Only 1 low shelf filter
 * - Only 1 high shelf filter
 * - Max 8 peaking filters
 * - Max 10 filters in total
 * - Format of filters must match regex
 * @param text
 */
const validateTextSettings = (text) => {
  const lines = text
    .split("\n")
    .map((line) => line.trim())
    .filter(Boolean)

  if (!PREAMP_TEXT_PATTERN.test(lines[0])) {
    return ["The first line must be a preamp setting e.g. 'Preamp: -8.5 dB'"]
  }

  const filterLines = lines.splice(1)

  if (filterLines.length > 10) {
    return [`There is a limit of 10 filters, your file includes ${filterLines.length}`]
  }

  let errors = []
  let lowshelfFilterCount = 0
  let highshelfFilterCount = 0
  let peakingFilterCount = 0

  filterLines.forEach((line, i) => {
    const match = line.match(FILTER_TEXT_PATTERN)

    if (!match) {
      errors.push(`Line ${i + 1} is incorrectly formatted`)
      return
    }

    let [_, filterNumber, status, filterType, frequency, gain, qFactor] = match

    if (filterType === "LSC") {
      if (lowshelfFilterCount > 0) {
        errors.push("Multiple lowshelf filters found. Only 1 is supported.")
        return
      }
      lowshelfFilterCount++
    } else if (filterType === "HSC") {
      if (highshelfFilterCount > 0) {
        errors.push("Multiple highshelf filters found. Only 1 is supported.")
        return
      }
      highshelfFilterCount++
    } else if (filterType === "PK") {
      if (peakingFilterCount >= 8) {
        errors.push("Maximum of 8 peaking filters allowed.")
        return
      }
      peakingFilterCount++
    }

    frequency = parseInt(frequency)
    if (frequency > 20000 || frequency < 20) {
      errors.push(`Frequency on line ${i + 1} must be between 20 and 20,000 Hz`)
      return
    }

    gain = parseInt(gain)
    if (gain > 30 || gain < -30) {
      errors.push(`Gain on line ${i + 1} must be between -30 and 30 dB`)
      return
    }

    qFactor = parseFloat(qFactor)
    if (qFactor < 0.1 || qFactor === NaN) {
      // Common minimum for all filter types
      errors.push(
        `Invalid Q of ${qFactor} on filter ${i + 1}. Q must be between least 0.1. Try a default value of 0.707.`
      )
      return
    }

    // Different max Q based on filter type
    if (filterType === "PK" && qFactor > 50) {
      errors.push(
        `Invalid Q of ${qFactor} on filter ${i + 1}. Peaking filters must have Q between 0.1 and 50`
      )
      return
    } else if ((filterType === "LSC" || filterType === "HSC") && qFactor > 3) {
      errors.push(
        `Invalid Q of ${qFactor} on filter ${i + 1}. Shelf filters must have Q between 0.1 and 3`
      )
      return
    }
  })

  return errors
}

const getShortFilterType = (longFilterType) => {
  if (longFilterType === "Highshelf") {
    return "HSC"
  } else if (longFilterType === "Lowshelf") {
    return "LSC"
  } else if (longFilterType.startsWith("Peaking")) {
    return "PK"
  }
  new Error(`Error converting '${longFilterType}' to short filter type`)
}

const getLongFilterType = (shortFilterType, filterNumber) => {
  if (shortFilterType === "LSC") {
    return "Lowshelf"
  } else if (shortFilterType === "HSC") {
    return "Highshelf"
  } else if (shortFilterType === "PK") {
    if (filterNumber) {
      return `Peaking ${filterNumber}`
    }
    return `Peaking`
  }
  new Error(`Error converting '${shortFilterType}' to long filter type`)
}

const createEqSettingValue = (gain, frequency, qFactor, filterType) => {
  return {
    Gain: {
      Min: -30,
      Max: 30,
      Current: Math.round(parseFloat(gain) * 100) / 100, // Round to 2 decimal places
    },
    Frequency: {
      Min: 20,
      Max: 20000,
      Current: Math.round(parseFloat(frequency)),
    },
    Q: {
      Min: 0.1,
      Max: filterType === "PK" ? 50 : 3,
      Current: Math.round(parseFloat(qFactor) * 1000) / 1000, // Round to 3 decimal places
    },
  }
}

/**
 * Convert our local EQ settings from the device (formatted as a JSON object)
 * into a Squiglink-like text file that can be exported by the user.
 *
 * @param eqSettings
 * @returns string
 */
const eqSettingsToText = (eqSettings) => {
  const filterOrder = [
    "Lowshelf",
    "Peaking 1",
    "Peaking 2",
    "Peaking 3",
    "Peaking 4",
    "Peaking 5",
    "Peaking 6",
    "Peaking 7",
    "Peaking 8",
    "Highshelf",
  ]

  const lines = filterOrder.map((key, i) => {
    const value = eqSettings[key]
    if (!value) {
      console.warn(`Missing filter: ${key}`)
      return `Filter ${i + 1}: OFF PK Fc 1000 Hz Gain 0 dB Q 0.707`
    }

    const status = value.Gain.Current === 0 ? "OFF" : "ON"
    let filterType = getShortFilterType(key)

    return `Filter ${i + 1}: ${status} ${filterType} Fc ${value.Frequency.Current.toFixed(1)} Hz Gain ${value.Gain.Current.toFixed(0)} dB Q ${value.Q.Current.toFixed(3)}`
  })

  // Add Preamp line at the beginning
  let preamp = 0
  try {
    preamp = parseFloat(eqSettings.Preamp.Gain.Current)
  } catch (e) {
    console.warn("Error parsing preamp value")
  } finally {
    lines.unshift(`Preamp: ${preamp} dB`)
  }

  return lines.join("\n")
}

/**
 * Convert Squiglink text presets to our own custom JSON format.
 *
 * These text presets are formatted like:
 *
 * Preamp: -2.9 dB
 * Filter 1: ON PK Fc 20 Hz Gain -1.6 dB Q 2.000
 * Filter 2: ON PK Fc 35 Hz Gain -6.0 dB Q 0.500
 * Filter 3: ON PK Fc 250 Hz Gain 2.1 dB Q 0.500
 * Filter 4: ON PK Fc 4000 Hz Gain -0.8 dB Q 2.000
 * Filter 5: ON PK Fc 5500 Hz Gain -3.7 dB Q 1.000
 * Filter 6: ON PK Fc 7100 Hz Gain 1.6 dB Q 1.000
 * Filter 7: ON PK Fc 8000 Hz Gain -3.0 dB Q 2.000
 * Filter 8: ON PK Fc 8300 Hz Gain -3.0 dB Q 2.000
 * Filter 9: ON PK Fc 10000 Hz Gain 5.6 dB Q 2.000
 * Filter 10: OFF PK Fc 0 Hz Gain 0.0 dB Q 0.000
 *
 * While the result will look like:
 *
 * {
 *   "Lowshelf": {
 *     "Frequency": {
 *       "Min": -30,
 *       "Max": 10000,
 *       "Current": 200
 *     },
 *     ...
 *   },
 *   ...
 * }
 *
 * @param text
 */
const textToEqSettings = (text) => {
  const eqSettings = {
    Preamp: {
      Mode: {
        Elements: ["AUTO", "MANUAL"],
        Current: "AUTO",
      },
      Gain: {
        Min: -30,
        Max: 3,
        Current: 0, // Default, will be updated from the text
      },
    },
  }

  const lines = text
    .split("\n")
    .map((line) => line.trim())
    .filter(Boolean)

  const preampMatch = lines[0].match(PREAMP_TEXT_PATTERN)
  if (preampMatch) {
    eqSettings.Preamp.Gain.Current = parseFloat(preampMatch[1])
  }

  const filterLines = lines.splice(1)

  const filterOrder = [
    "Lowshelf",
    "Peaking 1",
    "Peaking 2",
    "Peaking 3",
    "Peaking 4",
    "Peaking 5",
    "Peaking 6",
    "Peaking 7",
    "Peaking 8",
    "Highshelf",
  ]

  // Ensure we always have 10 filters
  for (let i = 0; i < 10; i++) {
    const line = filterLines[i] || getDefaultFilterLine(i)
    const match = line.match(FILTER_TEXT_PATTERN)
    if (match) {
      console.log(`Parsing line: ${line}`)
      console.log(`Match array:`, match)
      const filterNumber = parseInt(match[1])
      const isOn = match[2] === "ON"
      const filterType = match[3]
      const frequency = parseFloat(match[4])
      let gain = parseFloat(match[5])
      const qFactor = parseFloat(match[6])

      // Ensure gain is 0 for OFF filters
      if (!isOn) {
        gain = 0
      }

      //console.log(`Parsed values: filterNumber=${filterNumber}, isOn=${isOn}, filterType=${filterType}, frequency=${frequency}, gain=${gain}, qFactor=${qFactor}`);

      const longFilterType = filterOrder[i]

      eqSettings[longFilterType] = createEqSettingValue(gain, frequency, qFactor)
      console.log(
        `Created setting for ${longFilterType}:`,
        JSON.stringify(eqSettings[longFilterType], null, 2)
      )
    } else {
      console.log(`No match for line: ${line}`)
    }
  }

  return eqSettings
}

/**
 * Convert out custom eq settings into an URL that can be shared with a user
 *
 * The EQ settings are encoded in a query param that looks like:
 *
 * ?q=HSC,10,-3,1.02|PK,5,1,34|...
 *
 * We use the | character to delimit each band in the EQ. Within each band, the
 * settings looks like "<type>,<gain>,<frequency>,<q factor>" where
 *
 * - <type> is the filter type and can be "LSC" (lowshelf), "HSC" (highshelf), "PK" (peaking)
 * - <gain> is the value of gain between -30 and 30
 * - <frequency> is between 20 and 20000
 * - <q factor> is between 0 and 100
 *
 * @param eqSettings
 * @returns
 */
const eqSettingsToURLParam = (eqSettings): string => {
  const arr = Object.entries(eqSettings)
    .filter(([key, val]) => key !== "Preamp")
    .map(([key, val], i) => {
      let filterType = getShortFilterType(key)
      return `${filterType},${val["Gain"]["Current"]},${val["Frequency"]["Current"]},${val["Q"]["Current"]}`
    })
  return arr.join("|")
}

/**
 * Convert a "share" URL param to usable equaliser settings
 *
 * A "share" URL encodes the equaliser state in a query parameter and looks like:
 *
 * HSC,10,-3,1.02|PK,5,1,34|...
 *
 * This function takes the URL and splits it up. It then validates the input
 * to make sure it looks correct. If then returns a regular EQ setting object
 * in the format:
 *
 * {
 *   "Lowshelf": {
 *     "Gain": {
 *       "Min": -30,
 *       "Max": 30,
 *       "Current": 10
 *     },
 *     ...
 *   },
 *   ...
 * }
 *
 * @param url
 * @returns
 */
const urlParamToEqSettings = (urlParam: string) => {
  const errors = validateUrlParamSettings(urlParam)
  if (errors.length > 0) {
    throw new Error(errors.join(","))
  }

  const eqTokens = urlParam.split("|")
  const eqSettings = {}
  let peakingCounter = 1
  eqTokens.forEach((eqToken, i) => {
    const [type, gain, frequency, qFactor] = eqToken.split(",")
    const filterType = getLongFilterType(type, peakingCounter++)
    eqSettings[filterType] = createEqSettingValue(gain, frequency, qFactor)
  })

  return eqSettings
}

const getDefaultFilterLine = (index) => {
  if (index === 0) return `Filter 1: OFF LSC Fc 100 Hz Gain 0 dB Q 0.707`
  if (index === 9) return `Filter 10: OFF HSC Fc 10000 Hz Gain 0 dB Q 0.707`
  return `Filter ${index + 1}: OFF PK Fc 1000 Hz Gain 0 dB Q 0.707`
}

/**
 * Reorder EQ settings to match the order of the filters
 *
 * @param eqSettings R
 * @returns
 */
const reorderEqSettings = (eqSettings) => {
  const order = [
    "Lowshelf",
    "Peaking 1",
    "Peaking 2",
    "Peaking 3",
    "Peaking 4",
    "Peaking 5",
    "Peaking 6",
    "Peaking 7",
    "Peaking 8",
    "Highshelf",
  ]

  const reorderedSettings = {}
  order.forEach((key) => {
    if (eqSettings[key]) {
      reorderedSettings[key] = eqSettings[key]
    }
  })

  // Include Preamp if it exists
  if (eqSettings.Preamp) {
    reorderedSettings.Preamp = eqSettings.Preamp
  }

  return reorderedSettings
}

export {
  eqSettingsToText,
  eqSettingsToURLParam,
  reorderEqSettings,
  textToEqSettings,
  urlParamToEqSettings,
  validateEqSettings,
  validateTextSettings,
  validateUrlParamSettings,
}
