const WarningCallout = ({ icon, heading, content }) => {
  return (
    <section className="flex flex-col gap-4 rounded-xl border border-tundora bg-jaguar px-6 py-10">
      {icon && <header className="flex justify-center">{icon}</header>}
      {heading && (
        <header className="text-md flex items-center gap-3 font-bold leading-none text-white">
          {heading}
        </header>
      )}
      {content && (
        <div className="flex flex-col gap-4 text-sm font-normal text-silver">{content}</div>
      )}
    </section>
  )
}

export default WarningCallout
