import PropTypes from "prop-types"

import { Input as NextUIInput } from "@nextui-org/input"

const TextInput = ({ theme = "default", ...props }) => {
  const styles = {
    default: {
      inputWrapper: [
        "px-6",
        "border border-mine-shaft rounded-lg data-[hover=true]:border-mine-shaft group-data-[focus=true]:border-silver",
      ],
      input: ["font-bulo text-base font-bold"],
    },
    shadow: {
      inputWrapper: [
        "px-4 h-10",
        "border border-earth rounded-lg data-[hover=true]:border-earth group-data-[focus=true]:border-white bg-mine-shaft !shadow-inside",
      ],
      input: ["font-bulo text-base font-bold placeholder:text-white placeholder:opacity-50"],
    },
  }

  return <NextUIInput variant="bordered" classNames={styles[theme]} {...props} />
}

TextInput.propTypes = {
  theme: PropTypes.oneOf(["default", "shadow"]),
}

export default TextInput
