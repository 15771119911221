import EqualizerPage from "@/routes/EqualizerPage"
import ErrorPage from "@/routes/ErrorPage"
import DeviceSettingsPage from "@/routes/DeviceSettingsPage"
import AccountPage from "@/routes/AccountPage"
import FirmwarePage from "@/routes/FirmwarePage"
import ChangePasswordPage from "@/routes/ChangePasswordPage"
import LoginPage from "@/routes/LoginPage"
import SignUpPage from "@/routes/SignUpPage"
import ForgotPasswordPage from "@/routes/ForgotPasswordPage"
import ResetPasswordPage from "@/routes/ResetPasswordPage"
import SignUpComplete from "@/routes/SignUpComplete"
import SignUpVerification from "@/routes/SignUpVerification"
import PrivacyPolicyPage from "@/routes/PrivacyPolicyPage"

const routes = [
  {
    path: "/",
    element: <EqualizerPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "device-settings",
    element: <DeviceSettingsPage />,
    children: [
      {
        path: "firmware",
        element: <FirmwarePage />,
      },
    ],
  },
  {
    path: "account",
    element: <AccountPage />,
    children: [
      {
        path: "change-password",
        element: <ChangePasswordPage />,
      },
    ],
  },
  {
    path: "login",
    element: <LoginPage />,
  },
  {
    path: "signup",
    element: <SignUpPage />,
  },
  {
    path: "signup-complete",
    element: <SignUpComplete />,
  },
  {
    path: "signup-verification",
    element: <SignUpVerification />,
  },
  {
    path: "forgot-password",
    element: <ForgotPasswordPage />,
  },
  {
    path: "reset-password",
    element: <ResetPasswordPage />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicyPage />,
  },
]

export default routes
