export const MODE_HEADPHONES = "Headphone"
export const MODE_RCA = "RCA"

export const JDSLABS_VENDOR_ID = 0x152a
export const JDS_LABS_DEVICE_IDS = {
  0x88fa: "JDS Labs Element IV",
}

export const UNKNOWN = "unknown"
export const DEVICE_UNSUPPORTED = "unsupported"
export const NO_DEVICES = "nodevices"
export const DEVICE_DISCONNECTED = "disconnected"
export const DEVICE_CONNECTING = "connecting"
export const DEVICE_CONNECTED = "connected"

export const ROOT_URL = "/"
export const DEVICE_SETTINGS_URL = "/device-settings"
export const FIRMWARE_URL = "/device-settings/firmware"
export const ACCOUNT_URL = "/account"
export const CHANGE_PASSWORD_URL = "/account/change-password"
export const LOGIN_URL = "/login"
export const CREATE_ACCOUNT_URL = "/signup"
export const FORGOT_PASSWORD_URL = "/forgot-password"
export const RESET_PASSWORD_URL = "/reset-password"
