import PropTypes from "prop-types"

const Container = ({ children }) => {
  return <div className="pl-14 pr-6">{children}</div>
}

Container.propTypes = {
  children: PropTypes.node,
}

export default Container
