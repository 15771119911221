import PropTypes from "prop-types"
import { Select as NextUISelect, SelectItem } from "@nextui-org/react"

const Select = ({ selected, items, ...props }) => {
  return (
    <NextUISelect
      radius="sm"
      size="lg"
      classNames={{
        trigger:
          "bg-dark border data-[hover=true]:bg-dark border-mine-shaft data-[hover=true]:border-silver transition-colors",
        value: "text-white group-data-[has-value=true]:text-white type-body-base font-bold",
        popoverContent: "bg-dark rounded-lg border border-mine-shaft p-px",
      }}
      listboxProps={{
        itemClasses: {
          base: [
            "py-2.5 rounded-md",
            "data-[hover=true]:text-white",
            "data-[hover=true]:bg-mine-shaft",
            "data-[selectable=true]:focus:text-white",
            "data-[selectable=true]:focus:bg-mine-shaft",
          ],
          title: "font-bulo text-base font-light",
        },
      }}
      popoverProps={{
        radius: "sm",
        placement: "bottom-start",
        motionProps: {
          variants: {
            initial: {
              opacity: 0,
              transform: "scaleY(0.9)",
            },
            enter: {
              opacity: 1,
              transform: "scaleY(1)",
              transition: {
                type: "spring",
                bounce: 0,
                duration: 0.3,
              },
            },
            exit: {
              opacity: 0,
              transform: "scaleY(0.96)",
              transition: {
                type: "easeOut",
                bounce: 0,
                duration: 0.15,
              },
            },
          },
        },
      }}
      defaultSelectedKeys={[selected]}
      disallowEmptySelection={true}
      {...props}
    >
      {items.map(({ label, value }) => (
        <SelectItem key={value}>{label}</SelectItem>
      ))}
    </NextUISelect>
  )
}

Select.propTypes = {
  selected: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })
  ),
}

export default Select
