import { ModalContent, ModalBody, ModalFooter } from "@nextui-org/modal"
import Modal from "@/components/modals/Modal"
import Button from "@/components/Button"

type Props = {
  question: string
  description?: string
  confirmText: string
  cancelText?: string
  onConfirm: () => void
}

const ConfirmationModal = ({
  question,
  description,
  confirmText,
  cancelText = "Cancel",
  onConfirm,
  ...props
}: Props) => {
  return (
    <Modal {...props}>
      <ModalContent>
        {(onClose) => (
          <>
            <ModalBody>
              <div className="type-heading-3xl font-bold text-white">{question}</div>
              {description && <div className="type-body-base text-spun-pearl">{description}</div>}
            </ModalBody>
            <ModalFooter className="justify-start">
              <div className="flex gap-2 pb-3">
                <Button
                  theme="secondary"
                  size="large"
                  onPress={() => {
                    onConfirm()
                    onClose()
                  }}
                >
                  {confirmText}
                </Button>
                <Button theme="neutral" size="large" onPress={onClose}>
                  {cancelText}
                </Button>
              </div>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}

export default ConfirmationModal
