import cx from "classnames"
import PropTypes from "prop-types"
import { Button as NextUIButton } from "@nextui-org/react"
import { NavLink } from "react-router-dom"

const Button = ({
  theme = "primary",
  size = "normal",
  outline,
  className,
  children,
  full,
  ...props
}) => {
  const classNames = cx(
    "clickable data-[pressed=true]:scale-1 inline-flex h-auto min-w-0 items-center gap-x-2 font-bulo font-bold",
    {
      /* Type */
      "bg-red text-white hover:bg-dark-red": !outline && theme === "primary",
      "bg-blue text-dark hover:bg-mid-blue": !outline && theme === "secondary",
      "border border-darkest-red bg-transparent text-red hover:border-dark-red":
        outline && theme === "primary",
      "border border-bright-blue bg-darkest-blue text-light-blue hover:border-blue":
        outline && theme === "secondary",
      "shadow-inside border-earth border bg-mine-shaft text-white": theme === "neutral",
      /* Size */
      "rounded px-3 pb-0.75 pt-1 text-sm": size === "small",
      "rounded-lg px-3 py-[0.6875rem] text-sm": size === "normal",
      "px-4 pb-2 pt-2 text-base": size === "large",
      /* Full */
      "w-full": full,
    },
    className
  )

  if (props.to) {
    props.as = NavLink
  }

  return (
    <NextUIButton className={classNames} {...props}>
      {children}
    </NextUIButton>
  )
}

Button.propTypes = {
  theme: PropTypes.oneOf(["primary", "secondary", "neutral"]),
  size: PropTypes.oneOf(["small", "normal", "large"]),
  /* If true, render button outline style */
  outline: PropTypes.bool,
  /* If true, render button in full width */
  full: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
}

export default Button
