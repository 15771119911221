import Button from "@/components/Button"
import TextInput from "@/components/forms/TextInput"
import LoginLayout from "@/components/layouts/LoginLayout"
import { AuthError, useAuth } from "@/contexts/auth"
import { ErrorMessage, Field, Form, Formik } from "formik"
import * as yup from "yup"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

const SignUpPage = () => {
  const navigate = useNavigate()
  const { register } = useAuth()
  return (
    <LoginLayout title="Create account" subtitle="Let's get you setup" showBackButton wide>
      <Formik
        initialValues={{
          name: "",
          email: "",
          password: "",
          password_confirm: "",
        }}
        validationSchema={yup.object({
          name: yup.string().required("Name is required"),
          email: yup.string().email().required("Email is required"),
          password: yup.string().min(8).required("Password is required"),
          password_confirm: yup
            .string()
            .min(8)
            .required("Password confirmation is required")
            .oneOf([yup.ref("password")], "Passwords must match"),
        })}
        onSubmit={async (values) => {
          try {
            await register(values)
            navigate("/signup-complete")
          } catch (error) {
            console.error(error)
            if (error instanceof AuthError) {
              if (error.code) {
                toast.error(error.message)
              } else {
                toast.error("Something went wrong. Please contact support.")
              }
            }
          }
        }}
      >
        <Form>
          <div className="space-y-6">
            <div>
              <Field
                as={TextInput}
                type="text"
                theme="shadow"
                name="name"
                placeholder="Name"
                autoFocus={true}
              />
              <ErrorMessage component="div" className="mt-1 text-xs text-red" name="name" />
            </div>
            <div>
              <Field as={TextInput} name="email" type="email" theme="shadow" placeholder="Email" />
              <ErrorMessage component="div" className="mt-1 text-xs text-red" name="email" />
            </div>
            <div>
              <Field
                as={TextInput}
                type="password"
                theme="shadow"
                name="password"
                placeholder="Password"
              />
              <ErrorMessage component="div" className="mt-1 text-xs text-red" name="password" />
            </div>
            <div>
              <Field
                as={TextInput}
                type="password"
                theme="shadow"
                name="password_confirm"
                placeholder="Confirm password"
              />
              <ErrorMessage
                component="div"
                className="mt-1 text-xs text-red"
                name="password_confirm"
              />
            </div>
            <Button type="submit" full>
              Sign up
            </Button>
          </div>
        </Form>
      </Formik>
    </LoginLayout>
  )
}

export default SignUpPage
