import Layout from "@/components/layouts/DefaultLayout"
import { LOGIN_URL } from "@/constants"
import { ReactComponent as ArrowLeftIcon } from "@/icons/arrow-left-icon.svg"
import cx from "classnames"
import { ReactNode } from "react"
import { NavLink } from "react-router-dom"

type Props = {
  title: string
  subtitle: ReactNode | string
  showBackButton?: boolean
  wide?: boolean
  children: ReactNode
}

const LoginLayout = ({ title, subtitle, wide, showBackButton, children }: Props) => {
  return (
    <Layout showBackground>
      <img
        className="absolute left-0 top-0 z-0 h-screen w-screen object-cover"
        src={`${process.env.PUBLIC_URL}/login-view-background.jpg`}
        alt="JDS Element 4"
      />
      <div className="relative z-10 flex h-full w-screen">
        <div className="ml-auto w-2/5 border-l border-mine-shaft pl-[6.5vw] pr-8 pt-[18vh]">
          <div className={cx("relative pt-[10vh]", wide ? "max-w-[26.5rem]" : "max-w-[21.25rem]")}>
            {showBackButton && (
              <NavLink
                to={LOGIN_URL}
                className="type-body-base group absolute -left-6 top-0 inline-flex items-center gap-x-2 font-bold text-white"
              >
                <ArrowLeftIcon className="h-4 w-4 transition-transform group-hover:-translate-x-1" />
                Back to Log in
              </NavLink>
            )}
            <h2 className="-mr-6 font-pfdin text-[1.75rem] font-bold uppercase tracking-widest2x text-white">
              {title}
            </h2>
            <div className="type-body-base mt-1 text-silver">{subtitle}</div>
            <hr className="-mx-6 mt-3 h-px border-0 bg-mine-shaft" />
            <div className="mt-12">{children}</div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default LoginLayout
