import { ModalContent, ModalBody, ModalFooter } from "@nextui-org/modal"
import Modal from "@/components/modals/Modal"
import Button from "@/components/Button"

type Props = {
  title: string
  description?: string
  continueText: string
  onContinue: () => void
}

const ConfirmationModal = ({ title, description, continueText, onContinue, ...props }: Props) => {
  return (
    <Modal {...props} isDismissable={false} hideCloseButton={true}>
      <ModalContent>
        {(onClose) => (
          <>
            <ModalBody>
              <div className="type-heading-3xl font-bold text-white">{title}</div>
              {description && <div className="type-body-base text-spun-pearl">{description}</div>}
            </ModalBody>
            <ModalFooter className="justify-start">
              <div className="flex gap-2 pb-3">
                <Button
                  theme="secondary"
                  size="large"
                  onPress={() => {
                    onContinue()
                    onClose()
                  }}
                >
                  {continueText}
                </Button>
              </div>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}

export default ConfirmationModal
