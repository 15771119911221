import Layout from "@/components/layouts/DefaultLayout"

const EQLayout = ({ header, content, footer, sidebar }) => {
  return (
    <Layout>
      <div id="eq-main" className="flex h-full flex-1 flex-col gap-y-6 overflow-y-auto px-14 py-4">
        <div id="eq-footer" className="w-full flex-shrink-0">
          {header}
        </div>
        <div id="eq-content" className="flex min-h-0 flex-grow flex-col gap-y-7">
          {content}
        </div>
        <div id="eq-footer" className="w-full flex-shrink-0">
          {footer}
        </div>
      </div>
      <div id="eq-sidebar" className="hidden h-full w-[420px] flex-col lg:flex">
        {sidebar}
      </div>
    </Layout>
  )
}

export default EQLayout
