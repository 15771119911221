import Sidebar, {
  SidebarMenu,
  SidebarMenuLink,
  SidebarMenuButton,
} from "@/components/interface/Sidebar"
import { ACCOUNT_URL, CHANGE_PASSWORD_URL } from "@/constants"
import { useAuth } from "@/contexts/auth"
import { ReactComponent as ToolIcon } from "@/icons/tool-icon.svg"
import { ReactComponent as UserIcon } from "@/icons/user-icon.svg"
import { ReactComponent as LogoutIcon } from "@/icons/logout.svg"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"

const AccountSidebar = () => {
  const { logout } = useAuth()
  const navigate = useNavigate()
  return (
    <Sidebar>
      <SidebarMenu>
        <SidebarMenuLink link={ACCOUNT_URL} icon={<UserIcon />} label="Account Settings" />
        <SidebarMenuLink link={CHANGE_PASSWORD_URL} icon={<ToolIcon />} label="Reset Password" />
        <SidebarMenuButton
          onClick={async () => {
            await logout()
            toast.success("Logged out")
            navigate("/")
          }}
          icon={<LogoutIcon />}
          label="Logout"
        />
      </SidebarMenu>
    </Sidebar>
  )
}

export default AccountSidebar
