/**
 * Download a blob file to the local machine
 *
 * @param blob
 * @param mimeType
 * @param filename
 */
const downloadBlob = (blob: Blob, mimeType: string = "text/plain", filename: string) => {
  const link = document.createElement("a")
  link.href = window.URL.createObjectURL(blob)
  link.download = filename
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

/**
 * Download a base64 encoded file to the local machine
 *
 * @param base64
 * @param mimeType
 * @param filename
 */
const downloadBase64 = (base64: string, mimeType: string = "text/plain", filename: string) => {
  const link = document.createElement("a")
  link.href = `data:${mimeType};base64,${base64}`
  link.download = filename
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

/**
 * Read the contents of a file (so that we can use with Async await)
 *
 * @param file
 * @returns
 */
const readFileAsync = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = () => reject(new Error("Error reading file"));
    reader.readAsText(file);
  });
};


export { downloadBlob, downloadBase64, readFileAsync }

