import { Accordion as NextUIAccordion, AccordionItem } from "@nextui-org/accordion"

import { ReactComponent as PlusIcon } from "@/icons/plus-icon.svg"
import { ReactComponent as MinusIcon } from "@/icons/minus-icon.svg"

const Accordion = ({ items, ...props }) => {
  return (
    <NextUIAccordion
      showDivider={false}
      style={{ display: "flex", flexDirection: "column", gap: "1rem", padding: "0rem" }}
      itemClasses={{
        base: "border border-mine-shaft rounded-md",
        trigger: "py-2 px-4",
        title: "font-bulo text-base font-medium",
        content: "border-t border-mine-shaft px-4 py-5",
        indicator: "w-6 h-6 text-red",
      }}
      {...props}
    >
      {items.map(({ title, content }, index) => {
        return (
          <AccordionItem
            key={index}
            aria-label={title}
            title={title}
            indicator={({ isOpen }) => (isOpen ? <MinusIcon /> : <PlusIcon />)}
          >
            {content}
          </AccordionItem>
        )
      })}
    </NextUIAccordion>
  )
}

export default Accordion
