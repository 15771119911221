import { ReactComponent as CircleTickIcon } from "@/icons/circle-tick.svg"
import { ReactNode } from "react"
import cx from "classnames"

type Props = {
  level: string
  showIcon?: boolean
  children: ReactNode
}

const InlineNotification = ({ children, level = "success", showIcon = true }: Props) => {
  const outerClassnames = cx(
    "flex gap-4 rounded-lg border px-4 py-4",
    {
      "border-[#74a672] bg-[#263325]": level === "success",
      "border-[#f14a3e] bg-[#2a1c1b]": level === "error",
      "border-[#d07c40] bg-[#263325]": level === "warning",
    },
    {
      "items-center justify-between": showIcon,
      "items-center text-center justify-center": !showIcon,
    }
  )
  const iconClassNames = cx("w-10", {
    "text-[#74a672]": level === "success",
    "text-[#f14a3e]": level === "error",
    "text-[#d07c40]": level === "warning",
  })
  const innerClassNames = cx("leading-tight flex-grow text-left", {
    "text-[#80b87e]": level === "success",
    "text-[#e3463b]": level === "error",
    "text-[#d07c40]": level === "warning",
  })
  return (
    <div className={outerClassnames}>
      {showIcon && <CircleTickIcon className={iconClassNames} />}
      <p className={innerClassNames}>{children}</p>
    </div>
  )
}

export default InlineNotification
