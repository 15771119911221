import Button from "@/components/Button"
import { ROOT_URL } from "@/constants"

const ErrorPage = () => {
  return (
    <div className="flex h-screen w-screen items-center justify-center">
      <div className="max-w-lg text-center">
        <h1 className="type-heading-4xl font-bold">Ooops!</h1>
        <p className="type-body-base mt-2 text-spun-pearl">
          You shouldn't be here. Let's get you back on the track.
        </p>
        <Button to={ROOT_URL} className="mt-10">
          Back to the app
        </Button>
      </div>
    </div>
  )
}

export default ErrorPage
