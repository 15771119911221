/**
 * Compare two semver (e.g 1.2.3) strings
 *
 * @param version1
 * @param version2
 * @returns
 */
const compareVersionStrings = (version1: string, version2: string): number => {
  const parseVersion = (version) => version.split(".").map(Number)
  const v1 = parseVersion(version1)
  const v2 = parseVersion(version2)
  for (let i = 0; i < 3; i++) {
    if (v1[i] > v2[i]) return 1 // firmware1 is greater
    if (v1[i] < v2[i]) return -1 // firmware2 is greater
  }
  return 0
}

export { compareVersionStrings }
