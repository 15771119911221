import { useEffect, useRef } from "react"
import { gsap } from "gsap"
import { Draggable } from "gsap/Draggable"

type CanvasSize = {
  areaWidth: number
  areaHeight: number
}

type Band = {
  fc: number
  gain: number
  Q: number
  type: BiquadFilterType
  label: string
  bandLimits: {
    freq: { min: number; max: number }
    gain: { min: number; max: number }
    Q: { min: number; max: number }
  }
  els: {
    qSliderEl?: SVGGElement
    qSliderHandlerEl?: SVGElement
    draggableEl?: SVGElement
  }
  coords: {
    point?: {
      x: number
      y: number
    }
    limits?: {
      minX: number
      minY: number
      maxX: number
      maxY: number
    }
  }
  props: {
    midSize: number
    innerColor: string
    outerColor: string
    lastGain: number
  }
}

/**
 * Equalizer canvas
 *
 * This is an iteractive equaliser that lets the user's visually
 * configure their equaliser settings.
 *
 * Note that this was initially developed separately to the React frontend app,
 * as a stand-alone canvas-based app. It is therefore not fully developed in
 * the "React way".
 */
const CHART_CONFIG = {
  margin: 70,
  yAxisPadding: 10,
  fontSize: 22,
  pointerSize: 28,
  pointerMidSize: 0.8,
  qSlider: {
    width: 25,
    height: 100,
    stripeWidth: 4,
    bottomPadding: 10,
  },
  palette: {
    red: "#FF423E",
    activeFreqStroke: "#80211f",
    background: "#1E1E1E",
    mainGrid: "#3E3E3E",
    secondaryGrid: "#303030",
    secondaryGridText: "#5B5B5B",
    pointerMid: "#2E4360",
    pointerMidGrey: "#888888",
    pointerOuterBlue: "#6d7b90",
    pointerOuterWhite: "#F3EDED",
    pointerText: "#ffffff",
    limitsRGB: [89, 120, 162],
    backgroundDarkerRGB: [28, 30, 36],
  },
}

const GLOBAL_LIMITS = {
  freq: { min: 20, max: 20000 },
  gain: { min: -30, max: 30 },
  Q: { min: 0.3, max: 7, default: 0.707 },
}

const EqualizerCanvas = ({ eqSettings, mode, onEqChange }) => {
  const containerRef = useRef(null)
  const canvasRef = useRef<HTMLCanvasElement | null>(null)
  const canvasContext = useRef<CanvasRenderingContext2D | null>(null)
  const canvasSize = useRef<CanvasSize>({
    areaWidth: 0,
    areaHeight: 0,
  })
  const audioContext = useRef<AudioContext | null>()
  const svgRef = useRef<SVGSVGElement | null>(null)
  const frequencyResponse = useRef([])

  const activeBandAreaOpacity = useRef<number>(0)
  const activeBandIdx = useRef<number>(-1)
  const eqBands = useRef<Band[]>([])

  /**
   * Send update "up" to parent component so that the change can be sent to the
   * device and the rest of the interface can be updated with latest settings
   *
   * @param band
   */
  const onBandChange = (band) => {
    console.log("onBandChange", band)
    onEqChange([
      [band.label, "Frequency", band.fc],
      [band.label, "Gain", band.gain],
      [band.label, "Q", band.Q],
    ])
  }

  /**
   * When the page is resized, the layout needs to be updated
   *
   */
  const createInterface = () => {
    updateInterface()
    createSvgOverlay()
    reapplySvgBounds()
    // FIXME: Seems out of place here?
    eqBands.current.forEach((band) => {
      if (band.gain === 0) {
        deactivatePointer(band, 0)
      }
    })
  }

  /**
   * Update the interface by recalculating the curve and redrawing the canvas
   *
   * FIXME: This is potentially being called too oftern currently and could be
   * optimised.
   */
  const updateInterface = () => {
    calculateFrequencyResponse()
    eqBands.current.forEach((band) => {
      band.coords.point = {
        x: remapFreqToX(band.fc),
        y: remapGainToY(band.gain),
      }
    })
    drawCanvas()
  }

  /**
   * Draw the canvas elements including the frequency repsonse curve as well
   * as all of the grid lines and "non-interactive" EQ elements
   *
   */
  const drawCanvas = () => {
    canvasContext.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height)
    canvasContext.current.fillStyle = CHART_CONFIG.palette.background
    canvasContext.current.fillRect(0, 0, canvasRef.current.width, canvasRef.current.height)
    canvasContext.current.lineWidth = 1
    canvasContext.current.strokeStyle = CHART_CONFIG.palette.mainGrid
    canvasContext.current.strokeRect(
      CHART_CONFIG.margin,
      CHART_CONFIG.margin,
      canvasSize.current.areaWidth,
      canvasSize.current.areaHeight
    )

    const activeBand = eqBands.current[activeBandIdx.current]
    if (activeBand && activeBand.coords && activeBand.coords.limits) {
      canvasContext.current.fillStyle =
        "rgba(" +
        CHART_CONFIG.palette.backgroundDarkerRGB[0] +
        ", " +
        CHART_CONFIG.palette.backgroundDarkerRGB[1] +
        ", " +
        CHART_CONFIG.palette.backgroundDarkerRGB[2] +
        ", " +
        1 +
        ")"
      canvasContext.current.fillRect(
        activeBand.coords.limits.minX,
        activeBand.coords.limits.minY,
        activeBand.coords.limits.maxX - activeBand.coords.limits.minX,
        activeBand.coords.limits.maxY - activeBand.coords.limits.minY
      )
      canvasContext.current.fillStyle =
        "rgba(" +
        CHART_CONFIG.palette.limitsRGB[0] +
        ", " +
        CHART_CONFIG.palette.limitsRGB[1] +
        ", " +
        CHART_CONFIG.palette.limitsRGB[2] +
        ", " +
        0.07 * activeBandAreaOpacity.current +
        ")"
      canvasContext.current.fillRect(
        activeBand.coords.limits.minX,
        activeBand.coords.limits.minY,
        activeBand.coords.limits.maxX - activeBand.coords.limits.minX,
        activeBand.coords.limits.maxY - activeBand.coords.limits.minY
      )
    }

    // Format a frequency value in a human-readable format
    const fcFormatted = (fc: number): string => {
      if (fc < 1000) {
        return Math.round(fc).toString()
      } else {
        const rounded = Math.round(fc / 100) / 10
        return rounded % 1 === 0 ? `${rounded}k` : `${rounded.toFixed(1)}k`
      }
    }

    // Create an array of locations for the grid lines along the canvas
    const generateLogGrid = (min: number, max: number): number[] => {
      const minLog = Math.floor(Math.log10(min))
      const maxLog = Math.ceil(Math.log10(max))
      const gridLines = []
      for (let i = minLog; i <= maxLog; i++) {
        const base = Math.pow(10, i)
        gridLines.push(base)
        ;[2, 3, 5, 7].forEach((step) => {
          const refinedLine = step * base
          if (refinedLine >= min && refinedLine <= max) {
            gridLines.push(refinedLine)
          }
        })
      }
      return gridLines
    }

    // vertical secondary grid lines & numbers
    canvasContext.current.font = CHART_CONFIG.fontSize + "px Arial"
    canvasContext.current.strokeStyle = CHART_CONFIG.palette.secondaryGrid
    canvasContext.current.fillStyle = CHART_CONFIG.palette.secondaryGridText
    canvasContext.current.lineWidth = 1
    const gridLines = generateLogGrid(GLOBAL_LIMITS.freq.min, GLOBAL_LIMITS.freq.max)
    gridLines.forEach((fc) => {
      const x = remapFreqToX(fc)
      canvasContext.current.beginPath()
      canvasContext.current.moveTo(x, CHART_CONFIG.margin)
      canvasContext.current.lineTo(x, canvasSize.current.areaHeight + CHART_CONFIG.margin)
      canvasContext.current.stroke()
      canvasContext.current.fillText(
        fcFormatted(fc),
        x,
        CHART_CONFIG.margin + canvasSize.current.areaHeight + 30
      )
    })

    // vertical band lines + band freq text
    canvasContext.current.textAlign = "center"
    canvasContext.current.lineWidth = 2
    eqBands.current.forEach((band) => {
      const textX = band.coords.point.x
      const textY = CHART_CONFIG.margin + canvasSize.current.areaHeight + 30
      const txt = fcFormatted(band.fc)
      const w = canvasContext.current.measureText(txt).width
      canvasContext.current.fillStyle = CHART_CONFIG.palette.background
      canvasContext.current.fillRect(
        textX - 0.6 * w,
        textY - CHART_CONFIG.fontSize,
        1.4 * w,
        1.5 * CHART_CONFIG.fontSize
      )

      canvasContext.current.beginPath()
      canvasContext.current.moveTo(band.coords.point.x, CHART_CONFIG.margin)
      canvasContext.current.lineTo(
        band.coords.point.x,
        canvasSize.current.areaHeight + CHART_CONFIG.margin
      )
      canvasContext.current.strokeStyle = CHART_CONFIG.palette.mainGrid
      canvasContext.current.stroke()

      canvasContext.current.fillStyle = "#ffffff"
      canvasContext.current.fillText(txt, textX, textY)
    })

    // horizontal grid lines
    canvasContext.current.textAlign = "right"
    canvasContext.current.strokeStyle = CHART_CONFIG.palette.secondaryGrid
    canvasContext.current.fillStyle = CHART_CONFIG.palette.secondaryGridText
    const gainStep = 10
    const gainLabels = []
    for (let gain = GLOBAL_LIMITS.gain.min; gain <= GLOBAL_LIMITS.gain.max; gain += gainStep) {
      gainLabels.push(gain)
    }
    gainLabels.forEach((gain) => {
      const gainY = remapGainToY(gain)
      canvasContext.current.fillText(
        gain,
        CHART_CONFIG.margin - CHART_CONFIG.yAxisPadding,
        gainY + 5
      )
      canvasContext.current.beginPath()
      canvasContext.current.moveTo(CHART_CONFIG.margin, gainY)
      canvasContext.current.lineTo(CHART_CONFIG.margin + canvasSize.current.areaWidth, gainY)
      canvasContext.current.stroke()
    })

    // band limits
    canvasContext.current.lineWidth = 2
    if (activeBand) {
      canvasContext.current.strokeStyle =
        "rgba(" +
        CHART_CONFIG.palette.limitsRGB[0] +
        ", " +
        CHART_CONFIG.palette.limitsRGB[1] +
        ", " +
        CHART_CONFIG.palette.limitsRGB[2] +
        ", " +
        activeBandAreaOpacity.current +
        ")"
      canvasContext.current.setLineDash([6, 10])
      canvasContext.current.beginPath()
      canvasContext.current.moveTo(activeBand.coords.limits.minX, CHART_CONFIG.margin)
      canvasContext.current.lineTo(
        activeBand.coords.limits.minX,
        canvasSize.current.areaHeight + CHART_CONFIG.margin
      )
      canvasContext.current.stroke()
      canvasContext.current.beginPath()
      canvasContext.current.moveTo(activeBand.coords.limits.maxX, CHART_CONFIG.margin)
      canvasContext.current.lineTo(
        activeBand.coords.limits.maxX,
        canvasSize.current.areaHeight + CHART_CONFIG.margin
      )
      canvasContext.current.stroke()

      canvasContext.current.setLineDash([])
      canvasContext.current.shadowBlur = 10
      canvasContext.current.shadowColor = CHART_CONFIG.palette.activeFreqStroke
      canvasContext.current.strokeStyle = CHART_CONFIG.palette.activeFreqStroke
      canvasContext.current.beginPath()
      canvasContext.current.moveTo(activeBand.coords.point.x, CHART_CONFIG.margin)
      canvasContext.current.lineTo(
        activeBand.coords.point.x,
        canvasSize.current.areaHeight + CHART_CONFIG.margin
      )
      canvasContext.current.stroke()

      canvasContext.current.shadowBlur = 0
    }

    // curve itself
    canvasContext.current.strokeStyle = eqBands.current.every((band) => band.gain === 0)
      ? CHART_CONFIG.palette.mainGrid
      : CHART_CONFIG.palette.red
    canvasContext.current.lineWidth = 5
    canvasContext.current.beginPath()
    const coordinates = frequencyResponse.current.map((v) => ({
      x: remapFreqToX(v.frequency),
      y: remapGainToY(v.gain),
    }))
    canvasContext.current.moveTo(coordinates[0].x, coordinates[0].y)
    for (let i = 1; i < coordinates.length - 1; i++) {
      canvasContext.current.lineTo(coordinates[i].x, coordinates[i].y)
    }
    canvasContext.current.stroke()

    // band points
    eqBands.current.forEach((band, bandIdx) => {
      canvasContext.current.fillStyle = band.props.outerColor
      canvasContext.current.beginPath()
      canvasContext.current.arc(
        band.coords.point.x,
        band.coords.point.y,
        CHART_CONFIG.pointerSize,
        0,
        2 * Math.PI
      )
      canvasContext.current.fill()
      canvasContext.current.fillStyle = band.props.innerColor
      canvasContext.current.beginPath()
      canvasContext.current.arc(
        band.coords.point.x,
        band.coords.point.y,
        CHART_CONFIG.pointerSize * band.props.midSize,
        0,
        2 * Math.PI
      )
      canvasContext.current.fill()
      canvasContext.current.fillStyle = CHART_CONFIG.palette.pointerText
      const txt = String(bandIdx + 1)
      const w = canvasContext.current.measureText(txt).width
      canvasContext.current.fillText(
        String(bandIdx + 1),
        band.coords.point.x + 0.5 * w,
        band.coords.point.y + 0.3 * CHART_CONFIG.pointerSize
      )
    })
  }

  /**
   * Create an SVG overlay that contains all of the center points for the bands
   * as well as the Q sliders for each band. This overlay sits on top of the
   * canvas element below which is charting the response curve
   *
   */
  const createSvgOverlay = (): void => {
    svgRef.current.innerHTML = ""
    const pointHandleRadius = CHART_CONFIG.pointerSize * 2
    eqBands.current.forEach((band, bandIdx) => {
      createSvgDraggablePoint(band, bandIdx, pointHandleRadius)
      if (band.type === "peaking") {
        createSvgSliderQ(band)
      }
    })
  }

  /**
   * Create the DOM SVG element for center point for a each band. These are
   * the points that are draggable by the user.
   *
   * NOTE: they are hidden SVG elements - the actual visible points themselves
   * are drawn onto the canvas
   *
   * @param band
   * @param bandIdx
   * @param pointHandleRadius
   */
  const createSvgDraggablePoint = (
    band: Band,
    bandIdx: number,
    pointHandleRadius: number
  ): void => {
    band.els.draggableEl = document.createElementNS("http://www.w3.org/2000/svg", "circle")
    svgRef.current.appendChild(band.els.draggableEl)
    gsap.set(band.els.draggableEl, {
      attr: {
        cx: band.coords.point.x,
        cy: band.coords.point.y,
        r: pointHandleRadius,
        fill: "transparent",
      },
    })

    band.els.draggableEl.onclick = () => {
      gsap
        .timeline({
          onUpdate: updateInterface,
        })
        .to(band.props, {
          duration: 0.15,
          midSize: 1.2 * CHART_CONFIG.pointerMidSize,
        })
        .to(band.props, {
          duration: 0.1,
          midSize: CHART_CONFIG.pointerMidSize,
        })
    }

    const drag = Draggable.create(band.els.draggableEl, {
      type: "x,y",
      onDragStart: () => {
        activeBandIdx.current = bandIdx
        selectPointer(band)
      },
      onDrag: () => {
        const offsetX: number = gsap.getProperty(band.els.draggableEl, "x") as number
        const offsetY: number = gsap.getProperty(band.els.draggableEl, "y") as number

        band.coords.point.x =
          +band.els.draggableEl.getAttribute("cx") + offsetX - CHART_CONFIG.margin
        band.fc = remapXtoFreq(band.coords.point.x)
        band.coords.point.y = +band.els.draggableEl.getAttribute("cy") + offsetY

        const newGain = remapYToGain(band.coords.point.y)
        if (newGain !== band.gain) {
          if (newGain === 0) {
            deactivatePointer(band)
          } else {
            if (band.gain === 0) {
              activatePointer(band)
            }
          }
          band.gain = newGain
        }

        const boundaryDelta = Math.min(
          Math.abs(drag[0].minX - drag[0].x),
          Math.abs(drag[0].maxX - drag[0].x)
        )
        activeBandAreaOpacity.current = Math.pow(1 - Math.min(25, boundaryDelta) / 25, 2)

        updateInterface()
        if (band.els.qSliderEl) {
          gsap.set(band.els.qSliderEl, {
            x: +band.els.draggableEl.getAttribute("cx") + offsetX,
          })
        }
      },
      onDragEnd: () => {
        activeBandIdx.current = -1
        deselectPointer(band)
        const offsetX: number = gsap.getProperty(band.els.draggableEl, "x") as number
        const offsetY: number = gsap.getProperty(band.els.draggableEl, "y") as number
        gsap.set(band.els.draggableEl, {
          x: 0,
          y: 0,
          attr: {
            cx: +band.els.draggableEl.getAttribute("cx") + offsetX,
            cy: +band.els.draggableEl.getAttribute("cy") + offsetY,
          },
        })
        reapplySvgBounds()
        // Send update to parent
        onBandChange(band)
      },
    })

    band.els.draggableEl.addEventListener("dblclick", () => {
      if (band.gain === 0) {
        activatePointer(band)
      } else {
        band.props.lastGain = band.gain
        deactivatePointer(band)
      }

      gsap.to(band, {
        duration: 0.25,
        gain: band.gain === 0 ? band.props.lastGain : 0,
        onUpdate: () => {
          updateInterface()
        },
        onComplete: () => {
          createInterface()
          onBandChange(band)
        },
      })
    })
  }

  /**
   * Update the styling of the band center point
   *
   * @param band
   * @param duration
   */
  const selectPointer = (band: Band): void => {
    gsap.to(band.props, {
      duration: 0.1,
      outerColor: CHART_CONFIG.palette.pointerOuterBlue,
      midSize: 1.1 * CHART_CONFIG.pointerMidSize,
      onUpdate: updateInterface,
    })
  }

  /**
   * Update the styling of the band center point
   *
   * @param band
   * @param duration
   */
  const deselectPointer = (band: Band): void => {
    gsap.to(band.props, {
      duration: 0.3,
      outerColor: CHART_CONFIG.palette.pointerOuterWhite,
      midSize: CHART_CONFIG.pointerMidSize,
      onUpdate: updateInterface,
    })
  }

  /**
   * Update the styling of the band center point
   *
   * @param band
   * @param duration
   */
  const activatePointer = (band: Band): void => {
    gsap.to(band.props, {
      duration: 0.3,
      innerColor: CHART_CONFIG.palette.pointerMid,
      midSize: CHART_CONFIG.pointerMidSize,
      onUpdate: updateInterface,
    })
    if (band.els.qSliderEl) {
      gsap.to(band.els.qSliderEl, {
        duration: 0.1,
        opacity: 1,
      })
    }
  }

  /**
   * Update the styling of the band center point
   *
   * @param band
   * @param duration
   */
  const deactivatePointer = (band: Band, duration: number = 0.3) => {
    gsap.to(band.props, {
      duration,
      innerColor: CHART_CONFIG.palette.pointerMidGrey,
      midSize: 0.8 * CHART_CONFIG.pointerMidSize,
      onUpdate: updateInterface,
    })
    if (band.els.qSliderEl) {
      gsap.to(band.els.qSliderEl, {
        duration,
        opacity: 0.2,
      })
    }
  }

  /**
   * Create new SVG slider DOM element for a band, including a draggable point
   * on the slider.
   *
   * @param band
   */
  const createSvgSliderQ = (band: Band): void => {
    band.els.qSliderEl = document.createElementNS("http://www.w3.org/2000/svg", "g")
    gsap.set(band.els.qSliderEl, {
      x: +band.coords.point.x,
      y:
        canvasSize.current.areaHeight +
        CHART_CONFIG.margin -
        CHART_CONFIG.qSlider.width -
        CHART_CONFIG.qSlider.height -
        CHART_CONFIG.qSlider.bottomPadding,
    })
    svgRef.current.appendChild(band.els.qSliderEl)

    const track = document.createElementNS("http://www.w3.org/2000/svg", "path")
    band.els.qSliderEl.appendChild(track)
    gsap.set(track, {
      attr: {
        d: "M0," + 0.5 * CHART_CONFIG.qSlider.width + " v" + CHART_CONFIG.qSlider.height,
        stroke: CHART_CONFIG.palette.red,
        "stroke-width": CHART_CONFIG.qSlider.stripeWidth,
        "stroke-linecap": "round",
      },
    })

    const handlerOuterWrapper = document.createElementNS("http://www.w3.org/2000/svg", "g")
    const handlerWrapper = document.createElementNS("http://www.w3.org/2000/svg", "g")
    band.els.qSliderEl.appendChild(handlerOuterWrapper)
    handlerOuterWrapper.appendChild(handlerWrapper)

    const handleVisibleOuter = document.createElementNS("http://www.w3.org/2000/svg", "circle")
    handlerWrapper.appendChild(handleVisibleOuter)
    gsap.set(handleVisibleOuter, {
      attr: {
        cx: 0,
        cy: 0.5 * CHART_CONFIG.qSlider.width,
        r: 0.5 * CHART_CONFIG.qSlider.width,
        fill: "white",
      },
    })
    const handleVisibleInner = document.createElementNS("http://www.w3.org/2000/svg", "circle")
    handlerWrapper.appendChild(handleVisibleInner)
    gsap.set(handleVisibleInner, {
      attr: {
        cx: 0,
        cy: 0.5 * CHART_CONFIG.qSlider.width,
        r: 0.35 * CHART_CONFIG.qSlider.width,
        fill: CHART_CONFIG.palette.red,
      },
    })

    const handle = document.createElementNS("http://www.w3.org/2000/svg", "circle")
    handlerWrapper.appendChild(handle)
    gsap.set(handle, {
      attr: {
        cx: 0,
        cy: 0.5 * CHART_CONFIG.qSlider.width,
        r: CHART_CONFIG.qSlider.width,
        fill: "transparent",
      },
    })

    Draggable.create(handlerWrapper, {
      type: "y",
      bounds: {
        minY: 0,
        maxY: CHART_CONFIG.qSlider.height,
      },
      onDrag: () => {
        const offsetY: number = gsap.getProperty(handlerWrapper, "y") as number
        band.Q = qFromSlider(offsetY)
        updateInterface()
      },
      onDragEnd: () => {
        onBandChange(band)
      },
    })

    gsap.set(handlerWrapper, {
      y: sliderFromQ(band.Q),
    })

    handlerOuterWrapper.addEventListener("dblclick", () => {
      gsap.to(band, {
        duration: 0.1,
        Q: GLOBAL_LIMITS.Q.default,
        onUpdate: () => {
          gsap.set(handlerWrapper, {
            y: sliderFromQ(band.Q),
          })
          updateInterface()
        },
        onComplete: () => {
          onBandChange(band)
        },
      })
    })
  }

  /**
   * Updates the GSAP draggable boundaries for all of the bands so that the user
   * cannot place a band center point outside a certain limit
   *
   */
  const reapplySvgBounds = (): void => {
    let minX: number, minY: number, maxX: number, maxY: number
    minY = remapGainToY(GLOBAL_LIMITS.gain.min)
    maxY = remapGainToY(GLOBAL_LIMITS.gain.max)

    eqBands.current.forEach((band, bandIdx) => {
      if (bandIdx === 0) {
        // lowshelf filter
        minX = remapFreqToX(GLOBAL_LIMITS.freq.min)
      } else {
        minX = eqBands.current[bandIdx - 1].coords.point.x
      }

      if (bandIdx === eqBands.current.length - 1) {
        // highshelf filter
        maxX = remapFreqToX(GLOBAL_LIMITS.freq.max)
      } else {
        maxX = eqBands.current[bandIdx + 1].coords.point.x
      }

      band.coords.limits = { minX, maxX, minY, maxY }
    })

    eqBands.current.forEach((band) => {
      const draggable = Draggable.get(band.els.draggableEl)
      draggable.applyBounds({
        minX: band.coords.limits.minX - band.coords.point.x,
        maxX: band.coords.limits.maxX - band.coords.point.x,
        minY: band.coords.limits.minY - band.coords.point.y,
        maxY: band.coords.limits.maxY - band.coords.point.y,
      })
    })
  }

  /**
   * Converts a point along a slider (v) back to to Q value, rounding to 3 decimal places
   *
   * @param {*} v
   * @returns
   */
  const qFromSlider = (v: number): number => {
    let qNormalized = v / CHART_CONFIG.qSlider.height
    qNormalized = Math.pow(qNormalized, 4)
    const q = GLOBAL_LIMITS.Q.min + qNormalized * (GLOBAL_LIMITS.Q.max - GLOBAL_LIMITS.Q.min)
    const roundedQ = Math.round(q * 1000) / 1000
    return Math.min(GLOBAL_LIMITS.Q.max, Math.max(GLOBAL_LIMITS.Q.min, roundedQ))
  }

  /**
   * Converts the band's Q value into a point along the slider (v)
   *
   * @param {*} Q
   * @returns
   */
  const sliderFromQ = (Q: number): number => {
    let qNormalized = (Q - GLOBAL_LIMITS.Q.min) / (GLOBAL_LIMITS.Q.max - GLOBAL_LIMITS.Q.min)
    qNormalized = Math.pow(qNormalized, 1 / 4)
    return qNormalized * CHART_CONFIG.qSlider.height
  }

  /**
   * Converts a point on the plot (x) back to a frequency value, rounding to the nearest integer
   *
   * @param {*} x
   * @returns {number}
   */
  const remapXtoFreq = (x: number): number => {
    const minLog = Math.log10(GLOBAL_LIMITS.freq.min)
    const maxLog = Math.log10(GLOBAL_LIMITS.freq.max)
    const logValue = (x / canvasSize.current.areaWidth) * (maxLog - minLog) + minLog
    const fc = Math.pow(10, logValue)
    const roundedFc = Math.round(fc)
    return Math.min(GLOBAL_LIMITS.freq.max, Math.max(GLOBAL_LIMITS.freq.min, roundedFc))
  }

  /**
   * Converts a band's frequency value to a point on the plot (x)
   *
   * @param {*} value
   * @returns
   */
  const remapFreqToX = (freqValue: number): number => {
    const minLog = Math.log10(GLOBAL_LIMITS.freq.min)
    const maxLog = Math.log10(GLOBAL_LIMITS.freq.max)
    const logValue = Math.log10(freqValue)
    const v = (logValue - minLog) / (maxLog - minLog)
    return CHART_CONFIG.margin + v * canvasSize.current.areaWidth
  }

  /**
   * Converts a point (y) on the plot back to a gain value, rounding to the nearest 2 decimal places
   *
   * @param {*} y
   * @returns {number}
   */
  const remapYToGain = (y: number): number => {
    const gain =
      GLOBAL_LIMITS.gain.min +
      ((canvasSize.current.areaHeight - y + CHART_CONFIG.margin) / canvasSize.current.areaHeight) *
        (GLOBAL_LIMITS.gain.max - GLOBAL_LIMITS.gain.min)
    const roundedGain = Math.round(gain * 100) / 100
    return Math.min(GLOBAL_LIMITS.gain.max, Math.max(GLOBAL_LIMITS.gain.min, roundedGain))
  }

  /**
   * Converts a band's gain value to a point on the plot (y).
   *
   * @param {*} value
   * @returns
   */
  const remapGainToY = (gainValue: number): number => {
    return (
      canvasSize.current.areaHeight +
      CHART_CONFIG.margin -
      ((gainValue - GLOBAL_LIMITS.gain.min) / (GLOBAL_LIMITS.gain.max - GLOBAL_LIMITS.gain.min)) *
        canvasSize.current.areaHeight
    )
  }

  /**
   * Resizes the canvas and SVG elements to match the user's viewport
   *
   * @returns
   */
  const resizeCanvas = () => {
    canvasRef.current.width = containerRef.current.clientWidth * window.devicePixelRatio
    canvasRef.current.height = containerRef.current.clientHeight * window.devicePixelRatio
    gsap.set(svgRef.current, {
      attr: {
        viewBox: "0 0 " + canvasRef.current.width + " " + canvasRef.current.height,
      },
    })
    canvasSize.current.areaWidth = canvasRef.current.width - 2 * CHART_CONFIG.margin
    canvasSize.current.areaHeight = canvasRef.current.height - 2 * CHART_CONFIG.margin
  }

  /**
   * Create a bi-quad filter from the browser Audio API, used to calculate
   * the frequency response curve.
   *
   * @param band
   * @returns
   */
  const createBiquadFilter = (band: Band) => {
    const filter = audioContext.current.createBiquadFilter()
    filter.frequency.value = band.fc
    filter.gain.value = band.gain
    filter.Q.value = band.Q
    filter.type = band.type
    return filter
  }

  /**
   * Recalculate the frequency response curve
   *
   * This is an array of gain and frequency values that plots the curve based
   * on the settings supplied in the eqBands object.
   *
   * @returns
   */
  const calculateFrequencyResponse = (): void => {
    const pointsNumber = canvasSize.current.areaWidth
    const response = new Float32Array(pointsNumber).fill(0)
    const frequencies = new Float32Array(pointsNumber)
    const magResponse = new Float32Array(pointsNumber)
    const phaseResponse = new Float32Array(pointsNumber)

    // get an array of points to calculate freq => gain
    const step = Math.pow(GLOBAL_LIMITS.freq.max / GLOBAL_LIMITS.freq.min, 1 / (pointsNumber - 1))
    for (let i = 0; i < pointsNumber; i++) {
      frequencies[i] = GLOBAL_LIMITS.freq.min * Math.pow(step, i)
    }

    // accumulate the gain response through all the EQ filters
    eqBands.current.forEach((band) => {
      const filter = createBiquadFilter(band)
      filter.getFrequencyResponse(frequencies, magResponse, phaseResponse)
      for (let i = 0; i < pointsNumber; i++) {
        // to db
        response[i] += 20 * Math.log10(magResponse[i])
      }
    })

    frequencyResponse.current = Array.from(frequencies).map((freq, i) => ({
      frequency: freq,
      gain: response[i],
    }))
  }

  /**
   * Initialise the canvas and SVG elements on first page-load
   *
   */
  useEffect(() => {
    gsap.registerPlugin(Draggable)

    audioContext.current = new (window.AudioContext || window.webkitAudioContext)()
    canvasContext.current = canvasRef.current.getContext("2d")

    const onResize = () => {
      if (containerRef.current.clientWidth > 100) {
        resizeCanvas()
        createInterface()
      }
    }
    resizeCanvas()

    window.addEventListener("resize", onResize)

    const resizeObserver = new ResizeObserver(onResize)
    resizeObserver.observe(containerRef.current)

    return () => {
      window.removeEventListener("resize", onResize)
      resizeObserver.disconnect()
    }
  }, [])

  /**
   * Update our internal representation of the eq bands when the "outside" settings
   * from the app changes. This can happen from numerous user interactions:
   *
   * - The EQ table is updated
   * - A preset is loaded
   * - A preset is deleted
   * - A link it loaded
   * - etc.
   */
  useEffect(() => {
    if (typeof eqSettings !== "undefined") {
      eqBands.current = Object.entries(eqSettings)
        .filter(([key, value]) => key !== "Preamp")
        .map(([key, value]) => {
          return {
            fc: value["Frequency"]["Current"],
            gain: value["Gain"]["Current"],
            Q: value["Q"]["Current"],
            type: key.toLowerCase().replace(/\s\d+$/, "") as BiquadFilterType,
            label: key,
            bandLimits: {
              freq: { min: value["Frequency"]["Min"], max: value["Frequency"]["Max"] },
              gain: { min: value["Gain"]["Min"], max: value["Gain"]["Max"] },
              Q: { min: value["Q"]["Min"], max: value["Q"]["Max"] },
            },
            els: {},
            coords: {},
            props: {
              midSize: CHART_CONFIG.pointerMidSize,
              innerColor: CHART_CONFIG.palette.pointerMid,
              outerColor: CHART_CONFIG.palette.pointerOuterWhite,
              lastGain: 0,
            },
          }
        })
      if (svgRef.current && canvasRef.current) {
        createInterface()
      }
    }
  }, [eqSettings, mode])

  if (!eqSettings) {
    return <></>
  }

  return (
    <div id="equaliser-container" ref={containerRef} className="relative h-full w-full">
      <canvas ref={canvasRef} className="h-full w-full" />
      <svg ref={svgRef} className="absolute left-0 top-0 h-full w-full" />
    </div>
  )
}

export default EqualizerCanvas
