import Footer from "@/components/interface/Footer"
import Header from "@/components/interface/Header"
import { ReactNode } from "react"

type Props = {
  children: ReactNode
  showBackground?: boolean
}

const Layout = ({ children, showBackground = false }: Props) => {
  return (
    <div className="max-w-screen relative flex h-screen max-h-screen w-screen flex-col justify-between overflow-hidden">
      <Header showBackground={showBackground} />
      <main className="flex h-full flex-1 overflow-y-auto">{children}</main>
      <Footer showBackground={showBackground} />
    </div>
  )
}

export default Layout
