import { useSerialDevice } from "@/contexts/serial-device"
import { DEVICE_CONNECTED, DEVICE_DISCONNECTED, DEVICE_UNSUPPORTED, NO_DEVICES } from "@/constants"
import PropTypes from "prop-types"
import cx from "classnames"

const StatusDot = ({ small, className }) => {
  return (
    <div
      className={cx("rounded-full", className, {
        "mr-3 h-3 w-3": !small,
        "mr-2 h-2 w-2": small,
      })}
    ></div>
  )
}

const Unsupported = ({ small }) => {
  return (
    <>
      <StatusDot className="bg-yellow" />
      <span className="text-yellow">Unsupported Device</span>
    </>
  )
}

const Connected = () => {
  return (
    <>
      <StatusDot className="bg-green" />
      <span>Connected</span>
    </>
  )
}

const Disconnected = () => {
  return (
    <>
      <StatusDot className="bg-red" />
      <span className="text-red">Disconnected</span>
    </>
  )
}

const StatusBadge = ({ className, small }) => {
  const { status } = useSerialDevice()
  return (
    <div className={className}>
      <div
        className={cx("flex items-center border border-darkest-green pl-3 pr-4", {
          "type-body-base rounded-lg py-0.75 pb-0.75": !small,
          "type-body-xs rounded-full py-0.75 pb-0.75": small,
        })}
      >
        {status === DEVICE_UNSUPPORTED && <Unsupported small={small} />}
        {status === DEVICE_CONNECTED && <Connected small={small} />}
        {status === DEVICE_DISCONNECTED && <Disconnected small={small} />}
      </div>
    </div>
  )
}

StatusBadge.propTypes = {
  className: PropTypes.string,
  small: PropTypes.bool,
}

export default StatusBadge
