import WarningCallout from "@/components/WarningCallout"
import { ReactComponent as BrowserWarningIcon } from "@/icons/browser-warning.svg"

const MobileWarningOverlay = () => {
  return (
    <section className="fixed left-0 top-0 z-50 flex h-screen w-screen items-center justify-center bg-dark p-4 lg:hidden">
      <WarningCallout
        icon={<BrowserWarningIcon className="h-24" />}
        content={
          <>
            <p>
              Sorry, smartphones are currently not supported by JDS Labs Core. Please switch to a
              supported desktop browser:
            </p>
            <ul className="list-disc pl-5">
              <li>Google Chrome</li>
              <li>Microsoft Edge</li>
              <li>Opera</li>
              <li>Brave</li>
              <li>Firefox*</li>
            </ul>
            <p>
              *Firefox requires the{" "}
              <a
                href="https://addons.mozilla.org/en-US/firefox/addon/webserial-for-firefox/"
                className="text-red"
                target="_blank"
              >
                WebSerial for Firefox
              </a>{" "}
              extension.
            </p>
          </>
        }
      ></WarningCallout>
    </section>
  )
}

export default MobileWarningOverlay
