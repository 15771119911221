import cx from "classnames"

import { ReactComponent as PowerIcon } from "@/icons/power-icon.svg"

const PowerToggle = ({ isActive = false, disable }: { isActive: boolean; disable: () => void }) => {
  return (
    <button
      className={cx(
        "ml-2 flex h-5 w-5 items-center justify-center rounded-full border",
        isActive && "clickable",
        isActive ? "border-green bg-dark-green" : "border-silver bg-tundora"
      )}
      disabled={!isActive}
      onClick={() => disable()}
    >
      <PowerIcon className={cx("h-3 w-3", isActive ? "text-green" : "text-silver")} />
    </button>
  )
}

export default PowerToggle
