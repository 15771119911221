import PropTypes from "prop-types"
import { RadioGroup as NextUIRadioGroup, Radio } from "@nextui-org/radio"

const RadioGroup = ({ selected, items, className, ...props }) => {
  return (
    <NextUIRadioGroup
      className={className}
      orientation="horizontal"
      value={selected}
      classNames={{ wrapper: "gap-5" }}
      {...props}
    >
      {items.map(({ label, value }) => {
        return (
          <Radio
            key={value}
            value={value}
            classNames={{
              wrapper: [
                "w-4 h-4 bg-white border-ghost",
                "group-data-[selected=true]:bg-red group-data-[selected=true]:border-red",
                "group-data-[hover-unselected=true]:bg-white",
              ],
              control: "bg-white",
              label: "font-bulo text-base font-bold text-white",
            }}
          >
            {label}
          </Radio>
        )
      })}
    </NextUIRadioGroup>
  )
}

RadioGroup.propTypes = {
  selected: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })
  ),
  className: PropTypes.string,
}

export default RadioGroup
