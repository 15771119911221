/**
 * Build settings update from array of keys and value.
 *
 * Builds a nested object of settings. This allows us to build up an object containing
 * settings changes that can eventually be "applied" to a full settings configuration
 * object.
 *
 * @param
 * @param {array[string]} keys A array of strings to build the resulting object with, e.g. ["Configuration", "UI", "Screen Timeout"]
 * @param {string|number} value The value to apply to the nested object e.g. 20
 * @returns An object like:
 * {
 *   "Configuration": {
 *     "UI": {
 *       "Screen Timeout": 20
 *     }
 *   }
 * }
 */
const buildSettingsUpdate = (
  obj: object,
  keys: string[],
  value: string | boolean | number
): object => {
  keys.reduce((acc, key, index) => {
    // If this is the last key, assign the value
    if (index === keys.length - 1) {
      acc[key] = value
    } else {
      // If the key doesn't exist, create an empty object
      if (!acc[key]) {
        acc[key] = {}
      }
    }
    return acc[key] // Move deeper into the object
  }, obj)

  // Return the updated object
  return obj
}

/**
 * Apply a settings update. Essentially, deep merge two objects into a new object.
 * this is used when we want to apply changes that are either being sent or
 * received from the device to our local settings saved in the browser.
 *
 * @param {*} target
 * @param {*} source
 * @returns
 */
const applySettingsUpdate = (target, source) => {
  const output = { ...target } // Start by cloning the target object
  for (let key in source) {
    if (source[key] instanceof Object && key in target) {
      // Recursively merge if both are objects
      output[key] = applySettingsUpdate(target[key], source[key])
    } else {
      // Otherwise, copy the value from the source (overwriting target if necessary)
      output[key] = source[key]
    }
  }

  return output
}

/**
 * Convert to update settings from describe settings
 *
 * Transform settings that describe the device's current state to settings
 * that can be sent to the device to update the state.
 *
 * Settings that come *from* the device look like:
 *
 * Configuration: {
 *   UI: {
 *     "LED Mode": {
 *       Elements: ["ON", "5 MIN STANDBY", "WITH SCREEN"],
 *       Current: "ON",
 *     },
 *   }
 * }
 *
 * Whereas settings that we send *to* the device need to look like:
 *
 * Configuration: {
 *   UI: {
 *    "LED Mode": "ON"
 *   }
 * }
 *
 * This function converts from the former to the latter for an existing settings
 * object. This is useful when doing a factory reset for example.
 *
 * @param settings
 */
const toSettingsUpdate = (settings) => {
  const output = {}
  for (const key in settings) {
    if (typeof settings[key] === "object" && settings[key] !== null) {
      // If the object contains the "Current" key, replace the object with the "Current" value.
      if (settings[key].hasOwnProperty("Current")) {
        output[key] = settings[key].Current
      } else {
        // Otherwise, recursively process the nested object
        output[key] = toSettingsUpdate(settings[key])
      }
    }
  }
  return output
}

const fromSettingsUpdate = (settingsUpdate) => {
  const output = {}
  for (const key in settingsUpdate) {
    if (typeof settingsUpdate[key] === "object" && settingsUpdate[key] !== null) {
      // If it's an object, recursively process the nested object
      output[key] = fromSettingsUpdate(settingsUpdate[key])
    } else {
      // If it's not an object, replace the value with { Current: value }
      output[key] = { Current: settingsUpdate[key] }
    }
  }
  return output
}

export { buildSettingsUpdate, applySettingsUpdate, toSettingsUpdate, fromSettingsUpdate }
