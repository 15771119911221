import PropTypes from "prop-types"
import { Tooltip as NextUITooltip } from "@nextui-org/tooltip"

const Tooltip = ({ children, ...props }) => {
  return (
    <NextUITooltip
      classNames={{
        base: "before:bg-tundora",
        content: "px-5 py-2 bg-red rounded-md type-body-sm bg-tundora text-silver",
      }}
      closeDelay={0}
      motionProps={{
        variants: {
          exit: {
            opacity: 0,
            transition: {
              duration: 0.1,
              ease: "easeIn",
            },
          },
          enter: {
            opacity: 1,
            transition: {
              duration: 0.15,
              ease: "easeOut",
            },
          },
        },
      }}
      {...props}
    >
      {children}
    </NextUITooltip>
  )
}

Tooltip.propTypes = {
  children: PropTypes.node,
}

export default Tooltip
