import Button from "@/components/Button"
import TextInput from "@/components/forms/TextInput"
import LoginLayout from "@/components/layouts/LoginLayout"
import { AuthError, useAuth } from "@/contexts/auth"
import { Field, Form, Formik } from "formik"
import { NavLink } from "react-router-dom"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import { CREATE_ACCOUNT_URL, FORGOT_PASSWORD_URL } from "@/constants"

const LoginPage = () => {
  const navigate = useNavigate()
  const { login } = useAuth()

  const onSubmit = async (values) => {
    try {
      await login({
        email: values.email,
        password: values.password,
      })
      navigate("/")
    } catch (error) {
      console.error(error)
      if (error instanceof AuthError) {
        if (error.code) {
          toast.error(error.message)
        } else {
          toast.error("Something went wrong. Please contact support.")
        }
      }
      return
    }
  }

  return (
    <LoginLayout
      title="Log in"
      subtitle={
        <>
          Your first time here?{" "}
          <NavLink
            to={CREATE_ACCOUNT_URL}
            className="text-red transition-colors hover:text-dark-red"
          >
            Create an account
          </NavLink>
        </>
      }
    >
      <Formik initialValues={{ email: "", password: "" }} onSubmit={onSubmit}>
        <Form>
          <div className="space-y-6">
            <Field
              as={TextInput}
              type="email"
              theme="shadow"
              name="email"
              placeholder="Email"
              autoFocus={true}
            />
            <Field
              as={TextInput}
              type="password"
              theme="shadow"
              name="password"
              placeholder="Password"
            />
            <div className="space-y-7">
              <div className="flex items-center justify-end">
                <NavLink
                  to={FORGOT_PASSWORD_URL}
                  className="type-body-base text-red transition-colors hover:text-dark-red"
                >
                  Forgot password?
                </NavLink>
              </div>
              <Button type="submit" full>
                Log in
              </Button>
            </div>
          </div>
        </Form>
      </Formik>
    </LoginLayout>
  )
}

export default LoginPage
